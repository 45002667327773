const state = {
  session_messages: [],

  template_messages: [],

  filter_removed_template_messages:null,

  filter_template_messages:null,

  filter_whatsapp_campaign_users:null,

  filter_whatsapp_campaigns:null,

  approved_template_messages: [],

  selected_template_id: null,

  selected_template_variables: [],

  whatsapp_campaigns: [],

  campaign_users: [],

  campaign_details: {},

  session_message_type_options: [
    { value: "text", text: "Text" },
    { value: "image", text: "Image" },
    { value: "file", text: "File" },
    { value: "audio", text: "Audio" },
    { value: "video", text: "Video" },
    { value: "sticker", text: "Sticker" },
    { value: "list", text: "Interactive - List message" },
    { value: "quick_reply", text: "Interactive - Quick reply message" },
    { value: "location", text: "Location" },
    { value: "contact", text: "Contact" },
  ],

  quick_reply_type_options: [
    { value: "text", text: "Text" },
    { value: "image", text: "Image" },
    { value: "video", text: "Video" },
    { value: "file", text: "File" },
  ],

  template_message_categories: [
    { value: 'UTILITY', text: 'Utility'},
    { value: "MARKETING", text: "Marketing" },
    // { value: 'AUTHENTICATION', text: 'Authentication'},
  ],

  template_message_header_options: [
    // { value: null, text: 'None'},
    { value: "TEXT", text: "Text" },
    { value: 'IMAGE', text: 'Image'},
    { value: 'VIDEO', text: 'Video'},
    // { value: 'DOCUMENT', text: 'Document'},
    // { value: 'LOCATION', text: 'Location'},
  ],

  // TODO: Will add COPY_CODE later for Marketing
  marketingButtonOptions: [
    { value: null, text: "None" },
    // { value: 'copy_code', text: 'Button List' },
    { value: "button_list", text: "Button List" },
  ],
  utilityButtonOptions: [
    { value: null, text: "None" },
    { value: "button_list", text: "Button List" },
  ],
  buttonListOptions: [
    { value: "QUICK_REPLY", text: "Quick Reply" },
    { value: 'PHONE_NUMBER', text: 'Phone Number' },
    // { value: 'URL', text: 'URL' },
  ],

  //TODO
  session_message_api: "",

  session_message_settings: {
    text: {
      text_limit: 4096,
    },
    image: {
      caption_limit: 3000,
      image_accepted_types: "image/jpeg, image/jpg, image/png",
      image_size_limit: 5, //MB
    },
    file: {
      filename_limit: 240,
      file_accepted_types:
          "text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      file_size_limit: 100, //MB
    },

    audio: {
      audio_accepted_types:
          "audio/aac, audio/mp4, audio/amr, audio/mpeg, audio/ogg; codecs=opus",
      audio_size_limit: 16, //MB
    },

    video: {
      caption_limit: 4096,
      video_accepted_types: "video/mp4, video/3gpp",
      video_size_limit: 16, //MB
    },

    sticker: {
      sticker_accepted_types: "image/webp",
      sticker_size_limit: 0.1, //MB - 100KB
    },

    list: {
      header_limit: 60,
      body_limit: 1024,

      globalButtons: {
        title_limit: 20,
      },
      items: {
        title_limit: 24,
        subtitle_limit: 24,
        options: {
          title_limit: 24,
          description_limit: 72,
          total_option_limit: 10,
        },
      },
    },

    quick_reply: {
      header_limit: 20,
      body_limit: 1024,
      caption_limit: 60,
      options: {
        title_limit: 20,
        total_option_limit: 3,
      },
    },
  },

  template_message_settings: {
    vertical_limit: 180,
    header: {
      text_limit: 60,
      image_accepted_types: "image/jpeg, image/png",
      image_size_limit: 2, // MB
      video_accepted_types: "video/mp4",
      video_size_limit: 20, // MB
      document_accepted_types: "application/pdf",
      document_size_limit: 2, // MB
    },
    content_limit: 1028,
    footer_limit: 60,
    button_text_limit: 25,
    button_phone_text_limit: 20,
    buttons_limit: 10,
    button_phone_limit: 1,
  },

  whatsapp_campaign_settings: {
    document_accepted_types:
        "text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    document_size_limit: 50, // MB
  },

  // Campaign Pagination
  campaign_total: 1,
  campaign_current: 1,
  campaign_perpage: 1,

  // Campaign Users Pagination
  campaign_users_total: 1,
  campaign_users_current: 1,
  campaign_users_perpage: 1,


  // Template Messages Pagination
  template_total: 1,
  template_current: 1,
  template_perpage: 1,


};

const getters = {
  getSessionMessages: (state) => {
    return state.session_messages;
  },

  getSessionMessageSettings: (state) => {
    return state.session_message_settings;
  },

  getSessionMessageTypeOptions: (state) => {
    return state.session_message_type_options;
  },

  getQuickReplyTypeOptions: (state) => {
    return state.quick_reply_type_options;
  },

  getTemplateMessages: (state) => {
    return state.template_messages;
  },

  getFilterTemplateMessages: (state) => {
    return state.filter_template_messages;
  },
  getFilterRemovedTemplateMessages: (state) => {
    return state.filter_removed_template_messages;
  },
  getFilterWhatsappCampaignUsers: (state) => {
    return state.filter_whatsapp_campaign_users;
  },
  getFilterWhatsappCampaigns: (state) => {
    return state.filter_whatsapp_campaigns;
  },
  getApprovedTemplateMessages: (state) => {
    return state.approved_template_messages;
  },

  getSelectedTemplateId: (state) => {
    return state.selected_template_id;
  },

  getSelectedTemplateVariables: (state) => {
    return state.selected_template_variables;
  },

  getWhatsappCampaigns: (state) => {
    return state.whatsapp_campaigns;
  },
  getWhatsappCampaignDetails: (state) => {
    return state.campaign_details;
  },

  getTemplateMessageCategories: (state) => {
    return state.template_message_categories;
  },

  getTemplateMessageSettings: (state) => {
    return state.template_message_settings;
  },

  getTemplateMessageHeaderOptions: (state) => {
    return state.template_message_header_options;
  },

  getWhatsappCampaignSettings: (state) => {
    return state.whatsapp_campaign_settings;
  },

  getMarketingButtonOptions: (state) => {
    return state.marketingButtonOptions;
  },

  getUtilityButtonOptions: (state) => {
    return state.utilityButtonOptions;
  },

  getButtonListOptions: (state) => {
    return state.buttonListOptions;
  },

  // Return total page
  getCampaignTotal: (state) => {
    return state.campaign_total;
  },

  // Return current page
  getCampaignCurrent: (state) => {
    return state.campaign_current;
  },

  // Return per page
  getCampaignPerPage: (state) => {
    return state.campaign_perpage;
  },
  // Return per page
  getCampaignUsers: (state) => {
    return state.campaign_users;
  },

  // Return total page
  getCampaignUsersTotal: (state) => {
    return state.campaign_users_total;
  },

  // Return current page
  getCampaignUsersCurrent: (state) => {
    return state.campaign_users_current;
  },

  // Return per page
  getCampaignUsersPerPage: (state) => {
    return state.campaign_users_perpage;
  },

  // Return total page
  getTemplateTotal: (state) => {
    return state.template_total;
  },

  // Return current page
  getTemplateCurrent: (state) => {
    return state.template_current;
  },

  // Return per page
  getTemplatePerPage: (state) => {
    return state.template_perpage;
  },
};

const mutations = {
  setSessionMessages(state, session_messages) {
    state.session_messages = session_messages;
  },

  setTemplateMessages(state, template_messages) {
    state.template_messages = template_messages;
  },

  setApprovedTemplateMessages(state, approved_template_messages) {
    state.approved_template_messages = approved_template_messages;
  },

  setWhatsappCampaigns(state, whatsapp_campaigns) {
    state.whatsapp_campaigns = whatsapp_campaigns;
  },

  setWhatsappCampaignDetails(state, campaign_details) {
    state.campaign_details = campaign_details;
  },

  setSelectedTemplateId(state, selected_template_id) {
    state.selected_template_id = selected_template_id;
  },

  setSelectedTemplateVariables(state, selected_template_variables) {
    state.selected_template_variables = selected_template_variables;
  },

  // Set total
  setCampaignTotal(state, campaign_total) {
    state.campaign_total = campaign_total;
  },

  // Set total
  setCampaignCurrent(state, campaign_current) {
    state.campaign_current = campaign_current;
  },

  // Set per page
  setCampaignPerPage(state, campaign_perpage) {
    state.campaign_perpage = campaign_perpage;
  },

  setCampaignUsers(state, campaign_users) {
    state.campaign_users = campaign_users;
  },

  // Set total
  setCampaignUsersTotal(state, campaign_users_total) {
    state.campaign_users_total = campaign_users_total;
  },

  // Set total
  setCampaignUsersCurrent(state, campaign_users_current) {
    state.campaign_users_current = campaign_users_current;
  },

  // Set per page
  setCampaignUsersPerPage(state, campaign_users_perpage) {
    state.campaign_users_perpage = campaign_users_perpage;
  },

  // Set total
  setTemplateTotal(state, template_total) {
    state.template_total = template_total;
  },

  // Set total
  setTemplateCurrent(state, template_current) {
    state.template_current = template_current;
  },

  // Set per page
  setTemplatePerPage(state, template_perpage) {
    state.template_perpage = template_perpage;
  },
  setFilterRemovedTemplateMessages(state, filter) {
    state.filter_removed_template_messages = filter;
  },
  setFilterTemplateMessages(state, filter) {
    state.filter_template_messages = filter;
  },
  setFilterWhatsappCampaignUsers(state, filter) {
    state.filter_whatsapp_campaign_users = filter;
  },
  setFilterWhatsappCampaigns(state, filter) {
    state.filter_whatsapp_campaigns = filter;
  },
};

const actions = {
  async fetchSessionMessages({ state, dispatch, commit }) {
    //TODO: Axios Get Request
  },

  async createSessionMessage({ state, dispatch, commit }) {
    //TODO: Axios Post Request
  },

  async updateSessionMessage({ state, dispatch, commit }, id) {
    //TODO: Axios Put Request
  },

  async deleteSessionMessage({ state, dispatch, commit }, id) {
    //TODO: Axios Delete Request
  },

  async fetchTemplateMessageMediaHandleId({ state, dispatch, commit }) {
    //TODO: Axios Get Request
  },

  async fetchRemovedTemplateMessages({ state, dispatch, commit }, {whatsapp_accounts, page=1 , filter=''}) {

    let current_page = page ? page : 1
    const template_messages = [];

    commit("setTemplateMessages", []);


    dispatch("wait/start", "template_messages", { root: true });

    const template_messages_api = "/api/v1/whatsapp/account/";

    for (let account of whatsapp_accounts) {
      try {
        const response = await axios.get(
            `${template_messages_api + account.id}/templates/get-removed?page=${current_page}&filter=${filter}`
        );
        // console.log(response.data.data)
        const temps = response.data.data;
        for (const obj of temps) {
          obj.account_id = account.id;
          obj.account_username = account.username;
          obj.account_name = account.name;
        }
        template_messages.push(temps);

        // Set current
        commit('setTemplateCurrent', response.data.meta.current_page);

        // Set perpage
        commit('setTemplatePerPage', response.data.meta.per_page);

        // Set total
        commit('setTemplateTotal', response.data.meta.total);


      } catch (error) {
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));
      }
    }

    commit("setTemplateMessages", template_messages.flat());


    dispatch("wait/end", "template_messages", { root: true });

  },

  async fetchTemplateMessages({ state, dispatch, commit }, {whatsapp_accounts, page=1,filter=""}) {

    let current_page = page ? page : 1
    const template_messages = [];

    commit("setTemplateMessages", []);
    dispatch("wait/start", "template_messages", { root: true });

    const template_messages_api = "/api/v1/whatsapp/account/";

    for (let account of whatsapp_accounts) {
      try {
        const response = await axios.get(
            `${template_messages_api + account.id}/templates?page=${current_page}&filter=${filter}`
        );
        // console.log(response.data.data)
        const temps = response.data.data;
        for (const obj of temps) {
          obj.account_id = account.id;
          obj.account_username = account.username;
          obj.account_name = account.name;
        }
        template_messages.push(temps);

        // Set current
        commit('setTemplateCurrent', response.data.meta.current_page);

        // Set perpage
        commit('setTemplatePerPage', response.data.meta.per_page);

        // Set total
        commit('setTemplateTotal', response.data.meta.total);


      } catch (error) {
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));
      }
    }

    commit("setTemplateMessages", template_messages.flat());


    dispatch("wait/end", "template_messages", { root: true });

  },


  async fetchApprovedTemplateMessages({ state, dispatch, commit },whatsapp_accounts) {

    const template_messages = [];

    dispatch("wait/start", "approved_template_messages", { root: true });

    const template_messages_api = "/api/v1/whatsapp/account/";

    for (let account of whatsapp_accounts) {
      try {
        const response = await axios.get(
            `${template_messages_api + account.id}/templates?status=approved`
        );
        // console.log(response.data.data)
        const temps = response.data.data;
        for (const obj of temps) {
          obj.account_id = account.id;
          obj.account_username = account.username;
          obj.account_name = account.name;
        }
        template_messages.push(temps);
      } catch (error) {
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));
      }

      dispatch("wait/end", "approved_template_messages", { root: true });

    }

    commit("setApprovedTemplateMessages", template_messages.flat());
    // console.log(template_messages)

    dispatch("wait/end", "approved_template_messages", { root: true });
  },

  updateSelectedTemplateId({ state, dispatch, commit }, template_id) {
    commit('setSelectedTemplateId', template_id)
  },

  updateSelectedTemplateVariables({ state, dispatch, commit }, variables) {
    commit('setSelectedTemplateVariables', variables)
  },

  resetSelectedTemplateDetails({ state, dispatch, commit }) {
    dispatch('updateSelectedTemplateVariables', [])
    dispatch('updateSelectedTemplateId', null)
  },

  async refreshTemplateMessages({ state, dispatch, commit }, whatsapp_accounts) {
    dispatch("syncTemplateMessages", whatsapp_accounts);
    dispatch("fetchTemplateMessages", whatsapp_accounts);
  },

  async syncTemplateMessages({ state, dispatch, commit }, {whatsapp_accounts}) {
    const template_messages_api = "/api/v1/whatsapp/account/";

    for (let account of whatsapp_accounts) {
      try {
        await axios.get(`${template_messages_api + account.id}/templates/sync`);
      } catch (error) {
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));
      }
    }
  },

  async createTemplateMessage({ state, dispatch, commit }, { id, template }) {
    dispatch("wait/start", "template_message_setup", { root: true });

    const template_messages_api = "/api/v1/whatsapp/account/";

    await axios
        .post(`${template_messages_api + id}/templates`, template)
        .then((response) => {
          dispatch("wait/end", "template_message_setup", { root: true });
          Vue.toasted.success(Vue.i18n.translate("simple.success_info"));
        })
        .catch((error) => {
          Vue.toasted.error(Vue.i18n.translate("simple.error_info"));
          dispatch("wait/end", "template_message_setup", { root: true });
        });
  },

  async updateTemplateMessage({ state, dispatch, commit },{ account_id, template_id, template }) {
    dispatch("wait/start", "template_message_edit", { root: true });

    const template_messages_api = "/api/v1/whatsapp/account/";

    try {
      const response = await axios.patch(`${template_messages_api + account_id}/templates/${template_id}`,template)
      Vue.toasted.success(Vue.i18n.translate("simple.success_info"));

    } catch (error) {
      Vue.toasted.error(error.response.data.message);
    }

    dispatch("wait/end", "template_message_edit", { root: true });

  },

  async deleteTemplateMessage({ state, dispatch, commit },{ account_id, template_id }) {

    dispatch("wait/start", "template_messages", { root: true });

    const template_messages_api = "/api/v1/whatsapp/account/";

    await axios
        .delete(`${template_messages_api + account_id}/templates/${template_id}`)
        .then((response) => {
          dispatch("wait/end", "template_messages", { root: true });
          Vue.toasted.success(Vue.i18n.translate("simple.success_info"));
        })
        .catch((error) => {
          Vue.toasted.error(Vue.i18n.translate("simple.error_info"));
          dispatch("wait/end", "template_messages", { root: true });
        });
  },

  async createWhatsappCampaign({ state, dispatch, commit }, { id, name }) {

    dispatch("wait/start", "whatsapp_campaign_setup", { root: true });

    const whatsapp_campaign_api = "/api/v1/whatsapp/account/";

    try {
      const { data: response } = await axios.post(`${whatsapp_campaign_api + id}/campaigns`, {name});
      dispatch("wait/end", "whatsapp_campaign_setup", { root: true });
      Vue.toasted.success(Vue.i18n.translate("simple.success_info"));
      return response.data;
    } catch (error) {
      Vue.toasted.error(Vue.i18n.translate("simple.error_info"));
      dispatch("wait/end", "whatsapp_campaign_setup", { root: true });
    }
  },

  async fetchWhatsappCampaigns({ state, dispatch, commit }, { whatsapp_accounts, page = 1, filter = "" }) {
    let current_page = page ? page : 1;
    const whatsapp_campaigns = [];

    commit("setWhatsappCampaigns", []);

    dispatch("wait/start", "whatsapp_campaigns", { root: true });

    const whatsapp_campaign_api = "/api/v1/whatsapp/account/";

    for (let account of whatsapp_accounts) {
      try {
        const response = await axios.get(`${whatsapp_campaign_api + account.id}/campaigns?page=${current_page}&filter=${filter}`);

        const temps = response.data.data;
        for (const obj of temps) {
          obj.account_id = account.id;
          obj.account_username = account.username;
          obj.account_name = account.name;
        }
        whatsapp_campaigns.push(temps);

        // Set current
        commit('setCampaignCurrent', response.data.meta.current_page);

        // Set perpage
        commit('setCampaignPerPage', response.data.meta.per_page);

        // Set total
        commit('setCampaignTotal', response.data.meta.total);


      } catch (error) {
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));
      }
    }

    commit("setWhatsappCampaigns", whatsapp_campaigns.flat());


    dispatch("wait/end", "whatsapp_campaigns", { root: true });

  },

  updateWhatsappCampaignPage({ dispatch, commit, state }, {whatsapp_accounts, page}) {
    dispatch("fetchWhatsappCampaigns", {whatsapp_accounts: whatsapp_accounts ,page: page,});
  },

  async deleteWhatsappCampaign({ state, dispatch, commit },{ account_id, campaign_id }) {

    dispatch("wait/start", "whatsapp_campaigns_action", { root: true });

    const whatsapp_campaign_api = "/api/v1/whatsapp/account/";

    await axios
        .delete(`${whatsapp_campaign_api + account_id}/campaigns/${campaign_id}`)
        .then((response) => {
          Vue.toasted.success(Vue.i18n.translate("simple.success_info"))
          dispatch("wait/end", "whatsapp_campaigns_action", { root: true });
        })
        .catch((error) => {
          Vue.toasted.error(Vue.i18n.translate("simple.error_info"));
          dispatch("wait/end", "whatsapp_campaigns_action", { root: true });
        });
  },

  async updateWhatsappCampaign({ state, dispatch, commit },{ account_id, campaign_id, campaign }) {

    dispatch("wait/start", "whatsapp_campaign_edit", { root: true });

    const whatsapp_campaign_api = "/api/v1/whatsapp/account/";

    try {
      const response = await axios.patch(`${whatsapp_campaign_api + account_id}/campaigns/${campaign_id}`,campaign)
      Vue.toasted.success(Vue.i18n.translate("simple.success_info"));

    } catch (error) {
      Vue.toasted.error(error.response.data.message);
    }

    dispatch("wait/end", "whatsapp_campaign_edit", { root: true });

  },

  async startWhatsappCampaign({ state, dispatch, commit },{ account_id, campaign_id }) {

    dispatch("wait/start", "whatsapp_campaigns_action", { root: true });

    const whatsapp_campaign_api = "/api/v1/whatsapp/account/";

    try {
      await axios.post(`${whatsapp_campaign_api + account_id}/campaigns/${campaign_id}/action`)
      Vue.toasted.success(Vue.i18n.translate("simple.success_info"));

    } catch (error) {
      Vue.toasted.error(error.response.data.message);
    }

    dispatch("wait/end", "whatsapp_campaigns_action", { root: true });

  },

  async fetchWhatsappCampaignDetails({ state, dispatch, commit },{ account_id, campaign_id }) {

    dispatch("wait/start", "whatsapp_campaign_details", { root: true });

    commit("setWhatsappCampaignDetails", {});

    const whatsapp_campaign_api = "/api/v1/whatsapp/account/";

    try {
      const response = await axios.get(`${whatsapp_campaign_api + account_id}/campaigns/${campaign_id}`)
      commit("setWhatsappCampaignDetails", response.data.data);

    } catch (error) {
      Vue.toasted.error(error.response.data.message);
    }

    dispatch("wait/end", "whatsapp_campaign_details", { root: true });

  },

  async addUserToWhatsappCampaign({ state, dispatch, commit },{ account_id, campaign_id, file }) {

    dispatch("wait/start", "whatsapp_campaign_add_user", { root: true });

    const whatsapp_campaign_api = "/api/v1/whatsapp/account/";

    await axios.post(`${whatsapp_campaign_api + account_id}/campaigns/${campaign_id}/customers`, file)

    dispatch("wait/end", "whatsapp_campaign_add_user", { root: true });

  },

  async fetchWhatsappCampaingUsers({ state, dispatch, commit }, { account_id, campaign_id, page = 1, filter = "" }) {
    dispatch("wait/start", "whatsapp_campaign_users", { root: true });

    const whatsapp_campaign_api = "/api/v1/whatsapp/account/";

    commit("setCampaignUsers", []);

    await axios.get(`${whatsapp_campaign_api + account_id}/campaigns/${campaign_id}/customers?page=${page}&filter=${filter}`)
        .then((response) => {
          commit("setCampaignUsers", response.data.data.data);

          // Set current
          commit('setCampaignUsersCurrent', response.data.data.current_page);

          // Set perpage
          commit('setCampaignUsersPerPage', response.data.data.per_page);

          // Set total
          commit('setCampaignUsersTotal', response.data.data.total);
        })
        .catch((error) => {
          Vue.toasted.error(Vue.i18n.translate("simple.error_info"));
        });

    dispatch("wait/end", "whatsapp_campaign_users", { root: true });
  }
  ,


  updateWhatsappCampaignUsersPage({ dispatch, commit, state }, { account_id, campaign_id, page }) {
    dispatch("fetchWhatsappCampaingUsers", {account_id , campaign_id, page});
  },

  resetWhatsappCampaignUsers({ dispatch, commit, state }) {
    commit("setCampaignUsers", []);
  },

  async deleteWhatsappCampaignUsers({ state, dispatch, commit },{ account_id, campaign_id, customer_ids = [] }) {

    // dispatch("wait/start", "whatsapp_campaign_users", { root: true });

    const whatsapp_campaign_api = "/api/v1/whatsapp/account/";

    let id_url = ''
    if(customer_ids.length > 0) {
      customer_ids.forEach( (id, id_index) => {
        if(id_index === 0) {
          id_url += `?ids[]=${id}`
        } else {
          id_url += `&ids[]=${id}`
        }
      })
    }

    await axios.delete(`${whatsapp_campaign_api + account_id}/campaigns/${campaign_id}/customers${id_url}`)
        .then((response) => {
          // dispatch("wait/end", "whatsapp_campaign_users", { root: true });
          Vue.toasted.success(Vue.i18n.translate("simple.success_info"));
        })
        .catch((error) => {
          Vue.toasted.error(Vue.i18n.translate("simple.error_info"));
          // dispatch("wait/end", "whatsapp_campaign_users", { root: true });
        });
  },

  updateFilterTemplateMessages({commit},filter_value){
    commit("setFilterTemplateMessages", filter_value);
  },
  updateFilterRemovedTemplateMessages({commit},filter_value){
    commit("setFilterRemovedTemplateMessages", filter_value);
  },
  updateFilterWhatsappCampaignUsers({commit},filter_value){
    commit("setFilterWhatsappCampaignUsers", filter_value);
  },
  updateFilterWhatsappCampaigns({commit},filter_value){
    commit("setFilterWhatsappCampaigns", filter_value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
