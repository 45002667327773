<template>
    <b-row>
        <b-col class="pt-2">
            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <div>
                            <h4>{{ $t('simple.whatsapp_campaigns') }}</h4>
                            <div class="text-muted template-header-info"> {{ $t('simple.campaigns_desc') }}</div>
                        </div>

                        <div class="ml-auto align-self-center d-flex">

                            <b-button :variant="'info'" class="btn-sm mr-2 px-3" :to="{
                                name: 'whatsapp_campaign_setup'
                            }">
                                <font-awesome-icon :icon="'plus'" />
                                {{ $t('default.add') }}
                            </b-button>

                            <b-button :variant="'outline-info'" @click="refreshWhatsappCampaigns" class="btn-sm">
                                <font-awesome-icon :icon="'sync'" />
                                {{ $t('default.refresh') }}
                            </b-button>

                        </div>

                    </div>

                </div>

                <hr>

                <!-- Page content -->
                <div class="px-2">

                    <b-row>
                        <b-col>
                            <b-pagination hide-goto-end-buttons size="sm" :total-rows="total" :value="current"
                                @input="updatePage" :per-page="perpage">
                            </b-pagination>
                        </b-col>
                        <b-col md="4" class="my-2 ml-auto">
                            <b-input-group>
                                <b-form-input v-model="filter" :placeholder="$t('simple.type_to_search')" />
                                <b-input-group-append>
                                    <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                    </b-row>

                    <v-wait for="whatsapp_campaigns_action">
                        <loading slot="waiting" />
                    <b-table stacked="md" responsive bordered hover :items="whatsapp_campaigns"
                        :fields="window_width >= 1200 ? whatsapp_campaigns_fields : whatsapp_campaigns_fields_collapsed"
                        :per-page="perpage" class="vh-50">

                        <!-- Account -->
                        <template slot="cell(account_name)" slot-scope="cell">
                            <span v-b-tooltip.hover :title="cell.item.account_username">{{ cell.item.account_name }}
                                <font-awesome-icon :icon="'question-circle'" size="sm" /></span>
                        </template>

                        <template slot="cell(status)" slot-scope="cell">
                            <span
                                :class="{ 'text-danger': cell.item.status === 'processing', 'text-warning': cell.item.status === 'pending', 'text-success': cell.item.status === 'completed' }">
                                {{ cell.item.status.toUpperCase() }}
                            </span>

                        </template>


                        <template slot="cell(actions)" slot-scope="cell">
                            <b-button size="sm" @click="cell.toggleDetails()" v-if="window_width < 1200">
                                {{ cell.detailsShowing ? 'Hide' : 'Show' }} Details
                            </b-button>

                            <b-dropdown right :text="$t('simple.actions')" size="sm">

                                <template v-if="cell.item.status === 'pending'">

                                    <template v-if="cell.item.whatsapp_template && cell.item.customer_total > 0 ">
                                        <b-dropdown-item 
                                        @click="whatsappCampaignStart(cell.item.account_id, cell.item.id)">{{$t('simple.start_campaign') }}</b-dropdown-item>
                                    </template>

                                    <b-dropdown-item 
                                    @click="whatsappCampaignEdit(cell.item)">{{$t('simple.edit_campaign') }}</b-dropdown-item>

                                    <b-dropdown-item
                                    @click="deleteCampaign(cell.item.account_id, cell.item.id)">{{$t('simple.delete_campaign') }}</b-dropdown-item>
                                    
                                </template>

                                <template v-else>

                                    <b-dropdown-item 
                                    @click="whatsappCampaignDetails(cell.item)">{{$t('simple.campaign_details') }}</b-dropdown-item>
                                    
                                </template>

                                    

                            </b-dropdown>
                        </template>

                        <template #row-details="row">
                            <b-table small stacked="md" class="d-xl-none" responsive bordered hover :items="[row.item]"
                                :fields="whatsapp_campaigns_fields_not_collapsed">
                                <!-- Created at -->
                                <template slot="cell(created_at)" slot-scope="cell">

                                    {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm') }}

                                </template>

                                <!-- Updated at -->
                                <template slot="cell(updated_at)" slot-scope="cell">

                                    {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm') }}

                                </template>

                            </b-table>
                        </template>

                        <!-- Created at -->
                        <template slot="cell(created_at)" slot-scope="cell">

                            {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm') }}

                        </template>

                        <!-- Updated at -->
                        <template slot="cell(updated_at)" slot-scope="cell">

                            {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm') }}

                        </template>

                        <template slot="cell(type)" slot-scope="cell">

                            {{ cell.item.whatsapp_template ?  cell.item.whatsapp_template.template_type : '-'}}
                                                
                        </template>

                    </b-table>

                    </v-wait>


                    <v-wait for="whatsapp_campaigns">
                        <loading slot="waiting" />
                    </v-wait>

                </div>


            </div>

        </b-col>

    </b-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    metaInfo: function () {
        return {
            title: 'Whatsapp Campaigns',
        }
    },

    async mounted() {
        await this.fetchAccounts()
        await this.fetchWhatsappCampaigns({whatsapp_accounts:this.whatsapp_accounts})

        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },

    data() {
        return {
            window_width: window.innerWidth,
            whatsapp_campaigns_fields: [
                { key: 'account_name', label: this.$t('simple.account'), sortable: true },
                { key: 'name', label: this.$t('simple.name'), sortable: true },
                { key: 'type', label: this.$t('simple.type'), sortable: true },
                { key: 'status', label: this.$t('simple.status'), sortable: true },
                { key: 'customer_total', label: this.$t('simple.recipients'), sortable: true },
                { key: 'customer_sent', label: this.$t('simple.sent'), sortable: true },
                { key: 'customer_deliver', label: this.$t('simple.delivered'), sortable: true },
                { key: 'customer_read', label: this.$t('simple.read'), sortable: true },
                { key: 'created_at', label: this.$t('simple.created'), sortable: true },
                { key: 'actions', label: this.$t('simple.actions') },
            ],

            whatsapp_campaigns_fields_collapsed: [
                { key: 'account_name', label: this.$t('simple.account'), sortable: true },
                { key: 'name', label: this.$t('simple.name'), sortable: true },
                { key: 'type', label: this.$t('macros.template_type'), sortable: true },
                { key: 'status', label: this.$t('simple.status'), sortable: true },
                { key: 'customer_total', label: this.$t('simple.recipients'), sortable: true },
                { key: 'actions', label: this.$t('simple.actions') },
            ],

            whatsapp_campaigns_fields_not_collapsed: [
                { key: 'customer_sent', label: this.$t('simple.sent'), sortable: true },
                { key: 'customer_deliver', label: this.$t('simple.delivered'), sortable: true },
                { key: 'customer_read', label: this.$t('simple.read'), sortable: true },
                { key: 'created_at', label: this.$t('simple.created'), sortable: true },
            ],
            
        }
    },

    methods: {

        ...mapActions('Templates', [
            'fetchWhatsappCampaigns',
            'updateWhatsappCampaignPage',
            'deleteWhatsappCampaign',
            'startWhatsappCampaign',
            'updateFilterWhatsappCampaigns'
        ]),

        ...mapActions('Accounts', [
            'fetchAccounts',
        ]),

        updatePage(data){
            this.updateWhatsappCampaignPage({whatsapp_accounts: this.whatsapp_accounts, page: data})
        },

        onResize() {
            this.window_width = window.innerWidth;
        },

        async refreshWhatsappCampaigns() {
            await this.fetchWhatsappCampaigns({whatsapp_accounts:this.whatsapp_accounts})
        },

        async whatsappCampaignEdit(data) {
            await this.$router.push({ name: 'whatsapp_campaign_edit', params: { account_id: data.account_id, campaign_id: data.id } })
        },

        async whatsappCampaignDetails(data) {
            await this.$router.push({ name: 'whatsapp_campaign_details', params: { account_id: data.account_id, campaign_id: data.id } })
        },

        async deleteCampaign(account_id, campaign_id) {
            await this.deleteWhatsappCampaign({ account_id: account_id, campaign_id: campaign_id })
                .then(() => {
                    this.refreshWhatsappCampaigns()
                })
        },

        async whatsappCampaignStart(account_id, campaign_id) {
            await this.startWhatsappCampaign({ account_id: account_id, campaign_id: campaign_id })
                .then(() => {
                    this.refreshWhatsappCampaigns()
                })
        },

    },

    computed: {
        ...mapGetters({
            accounts: 'Accounts/getAccounts',
            whatsapp_campaigns: 'Templates/getWhatsappCampaigns',
            total: 'Templates/getCampaignTotal',
            current: 'Templates/getCampaignCurrent',
            perpage: 'Templates/getCampaignPerPage',
            getFilterWhatsappCampaigns: 'Templates/getFilterWhatsappCampaigns',
        }),

        whatsapp_accounts() {
            return this.accounts.filter(account => account.integration_id === 'whatsapp')
        },
      filter:{
        get(){
          return this.getFilterWhatsappCampaigns;
        },
        set(newFilter) {
          if (this.getFilterWhatsappCampaigns !== newFilter) {
            this.updateFilterWhatsappCampaigns(newFilter);
            this.fetchWhatsappCampaigns({
              whatsapp_accounts:this.whatsapp_accounts,
              page:1,
              filter: newFilter,
            });
          }
        }
      },
    },
}
</script>
