import { duration } from 'moment';
import router from '../../router'
import moment from 'moment';


const state = {
  // API
  notifications_api: '/api/v1/user/notifications',

  // Notification
  notification: false,
  notification_user_id: '',
  notifications: [],
  page_visibility: true,

  // Pagination
  total: false,
  current: false,
  perpage: false,

};

// getters
const getters = {

  // Get notification
  getNotification: (state) => {
    // Return notifications
    return state.notification;
  },

  // Get notifications
  getNotifications: (state) => {
    // Return notifications
    return state.notifications;
  },

  // Return total page
  getTotal: (state) => {
    return state.total;
  },

  // Return current page
  getCurrent: (state) => {
    return state.current;
  },

  // Return per page
  getPerPage: (state) => {
    return state.perpage;
  },
  // Return per page
  getPageVisibility: (state) => {
    return state.page_visibility;
  },

};

// mutations
const mutations = {

  // Set total
  setTotal (state, total){
    state.total = total;
  },

  // Set total
  setCurrent (state, current){
    state.current = current;
  },

  // Set per page
  setPerPage (state, perpage){
    state.perpage = perpage;
  },

  // Set notifications
  setNotifications (state, notifications){
    state.notifications = notifications;
  },

  // Set notifications unread
  setNotificationsUnread (state, notifications_unread){
    state.notifications_unread = notifications_unread;
  },

  // Set notification user id
  setNotificationUserID (state, user_id){
    state.notification_user_id = user_id;
  },
  // Set notification user id
  setPageVisibility (state, page_visibility){
    state.page_visibility = page_visibility;
  },


};

// actions
const actions = {

  // Refresh Notifications
  async refreshNotifications({ dispatch, state }){
    dispatch('fetchNotifications', state.current);

  },

  // Get notifications
  async fetchNotifications({ dispatch, commit, state, rootGetters}, page){

    // Check if we have page set or set it to default?
    let current_page = page ? page : 1;

    // Start waiting
    dispatch('wait/start', 'notifications', { root: true });

    // Clear pagination data
    dispatch('clearPagination');

    // Get Confirmations
    await axios.get(state.notifications_api + '?page=' + current_page)
        .then((response) => {

          // Assign notifications
          commit('setNotifications', response.data.data);

          // Set current
          commit('setCurrent', response.data.current_page);

          // Set perpage
          commit('setPerPage', response.data.per_page);

          // Set total
          commit('setTotal', response.data.total);

        })
        .catch((error) => {

          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);
        });
    // Stop waiting
    dispatch('wait/end', 'notifications', { root: true });
  },

  // Fetch unread
  async fetchUnread({ dispatch, commit, state}){

    // Start waiting
    dispatch('wait/start', 'notifications', { root: true });

    // Get last 5 unread notifications
    await axios.post(state.notifications_api + '/unread', {})

        .then((response) => {
          // Assign notifications
          commit('setNotificationsUnread', response.data);

        })
        .catch((error) => {

          console.log(error);

        });

    // Stop waiting
    dispatch('wait/end', 'notifications', { root: true });

  },

  // Update page
  updatePage({ dispatch, commit, state }, data) {

    // Update confirmations
    dispatch('fetchNotifications', data);

  },

  // Clear pagination data
  clearPagination({ dispatch, commit }) {

    // Set total to false
    commit('setTotal', false);

    // Set current to false
    commit('setCurrent', false);

    // Set per page to false
    commit('setPerPage', false);

  },

  // Update listen
  listen_user ({ commit, dispatch, getters, state}, user_id) {

    if (state.notification_user_id !== user_id) {

      // Welcome back
      dispatch('open', {message: Vue.i18n.translate('default.welcome') , description: Vue.i18n.translate('default.back')});

      // Set notification user id
      commit('setNotificationUserID', user_id);

      // Listen to
      Echo.private('users.' + user_id)

          .notification((data) => {

            console.log('data type');

            console.log(data.type);

            console.log(data);

            console.log(data.notification_type);

            // title
            let title;

            // desc
            let desc;

            switch (data.notification_type) {

              // Confirmation
              case 'confirmation':
                dispatch('Accounts/updateConfirmationCount', data, {root:true});
                // Title
                title = Vue.i18n.translate('confirmation.title');
                // Description
                desc = Vue.i18n.translate('confirmation.broadcast_notify',
                    {
                      id: data.confirmation_id,
                      user: data.confirmation_user,
                      status: data.confirmation_status,
                      reviewed_by: data.confirmation_reviewed_by,
                      reviewed_at: moment(moment.utc(data.confirmation_reviewed_at)).local().format('YYYY-MM-DD HH:mm:ss'),
                    }
                );
                break;

              // Confirmation request
              case 'confirmation_request':
                dispatch('Accounts/updateConfirmationCount', data, {root:true});
                // Title
                title = Vue.i18n.translate('confirmation.title');
                // Description
                desc = Vue.i18n.translate('confirmation.broadcast_request',
                    {
                      id: data.confirmation_id,
                      requested_by: data.confirmation_requested_by,
                      created_at: moment(moment.utc(data.confirmation_created_at)).local().format('YYYY-MM-DD HH:mm:ss'),
                    }
                );
                break;

                case 'confirmation_restricted_word':
                // Title
                title = Vue.i18n.translate('confirmation.restricted_word_title');
                // Description
                desc = Vue.i18n.translate('confirmation.restricted_word_broadcast_notify',
                    {
                      id: data.confirmation_id,
                      status: data.confirmation_status,
                      reviewed_by: data.confirmation_reviewed_by,
                      reviewed_at: moment(moment.utc(data.confirmation_reviewed_at)).local().format('YYYY-MM-DD HH:mm:ss'),
                    }
                );
                break;

                case 'confirmation_restricted_word_request':
                // Title
                title = Vue.i18n.translate('confirmation.restricted_word_title');
                // Description
                desc = Vue.i18n.translate('confirmation.restricted_word_broadcast_request',
                    {
                      id: data.confirmation_id,
                      requested_by: data.confirmation_requested_by,
                      created_at: moment(moment.utc(data.confirmation_created_at)).local().format('YYYY-MM-DD HH:mm:ss'),
                    }
                );
                break;

              // Transfer
              case 'transfer':
                dispatch('Accounts/updateTransferCount', data, {root:true});
                // Title
                title = Vue.i18n.translate('ticket.transfer');
                // Description
                desc = Vue.i18n.translate('ticket.transfer_broadcast_notify',
                    {
                          user: data.user,
                          case_id: data.case_id,
                        }
                    );
                break;

                // Transfer
                case 'twitter_limit':
                    let remainingTweet = data.account.limit - data.account.tweet_count

                    if(remainingTweet === 0){
                        // Title
                        title = Vue.i18n.translate('default.twitter_limit_title') ;
                        // Description
                        desc = Vue.i18n.translate('default.twitter_limit_notify',
                            {
                                limit: data.account.limit,
                                tweet_count: data.account.tweet_count,
                            }
                        );
                    }else if ( remainingTweet < 1000){
                        // Title
                        title = Vue.i18n.translate('default.twitter_limit_title') ;
                        // Description
                        desc = Vue.i18n.translate('default.twitter_limit_notify',
                            {
                                limit: data.account.limit,
                                tweet_count: data.account.tweet_count,
                            }
                        );
                    }


                    break;

              // Transfer Cancel
              case 'transfer_cancel':
                dispatch('Accounts/updateTransferCount', data, {root:true});
                // Title
                title = Vue.i18n.translate('ticket.transfer_canceled');
                // Description
                desc = Vue.i18n.translate('ticket.transfer_canceled_broadcast_notify',
                    {
                          user: data.user,
                          case_id: data.case_id,
                        }
                    );
                break;
                case 'crm_error':
                    // Title
                    title = Vue.i18n.translate('configuration.crm_error_title');
                    // Description
                    desc = ""
                    break;

                case 'popular':
                    // Title
                    title = Vue.i18n.translate('default.new_popular_message_arrived');
                    // Description
                    desc = Vue.i18n.translate('default.new_popular_message_broadcast',
                        {
                            customer: data.customer.username,
                            content: `${data.question.content}..`,
                        }
                    );
                    break;
                case 'vip':
                    // Title
                    title = Vue.i18n.translate('default.new_vip_account_message_arrived');
                    // Description
                    desc = Vue.i18n.translate('default.new_vip_account_message_broadcast',
                        {
                            customer: data.customer.username,
                            content: `${data.question.content}..`,
                        }
                    );
                    break;

            }

            if(data.notification_type === 'question' || data.notification_type === 'vip' || data.notification_type === 'confirmation_request' || data.notification_type === 'popular' ) {
              dispatch('openClickable', {message: title , description: desc, notification_data: data});
              dispatch('openSystemNotification', { title, desc, notification_data: data});

            }
            else if (data.notification_type === 'crm_error'){
                dispatch('openErrorClickable2', {message: title , description: desc, notification_data: data});
                dispatch('openSystemNotification', { title, desc, notification_data: data});
            }
            else if ( data.notification_type === 'confirmation_restricted_word_request') {
              dispatch('openErrorClickable', {message: title , description: desc});
              dispatch('openSystemNotification', { title, desc, notification_data: data});
            } else {
              dispatch('open', {message: title , description: desc});
              dispatch('openSystemNotification', {title , desc});

            }
            dispatch('playNotificationSound');
            
          });

          if(document.hasFocus()) {
            setTimeout(() => {
              Vue.prototype.$notification.destroy()
            }, 6000);
          }

          document.addEventListener('visibilitychange', () => {
            
            if(document.hidden) {
              dispatch('updatePageVisibility', false);
            } else {
              dispatch('updatePageVisibility', true);
              setTimeout(() => {
                Vue.prototype.$notification.destroy()
              }, 6000);
            }
          }, false);
          
    }
  },

  // Open notification
  open({state, getters}, data){
    Vue.prototype.$notification.open({
      ...data,
      duration: 0,
    });
    if(document.hasFocus()) {
      setTimeout(() => {
        Vue.prototype.$notification.destroy()
      }, 6000);
    }
  },

  updatePageVisibility({state, dispatch, commit}, page_visibility){
    commit('setPageVisibility', page_visibility)
  },

  openErrorClickable({state,getters, watch}, { message , description}) {
    Vue.prototype.$notification.error(
      {
        message,
        description,
        duration: 0,
        btn: h => {
          return h(
            'b-btn',
            {
              props: {
                variant: 'primary',
                size: 'sm',
              },
              on: {
                click: (event) => {
                  event.preventDefault();
                  router.push(`/confirmations/requests`)
                }
              },
            },
            Vue.i18n.translate('default.go_content'),
          );
        },
      }
    );
    if(document.hasFocus()) {
      setTimeout(() => {
        Vue.prototype.$notification.destroy()
      }, 6000);
    }
  },

  openClickable({state,getters, watch, dispatch}, { message , description, notification_data}){
    Vue.prototype.$notification.open(
      {
        message,
        description,
        duration: 0,
        btn: h => {
          return h(
            'b-btn',
            {
              props: {
                variant: 'primary',
                size: 'sm',
              },
              on: {
                click: (event) => {
                  event.preventDefault();
                  if(notification_data.notification_type === 'question') {
                    router.push(`/account/${notification_data.integration_id}/${notification_data.account_id}/questions/messages/${notification_data.customer_id}`)
                    dispatch("Inbox/updateSeen", notification_data.customer_id, { root: true });
                  }
                  else if (notification_data.notification_type === 'confirmation_request') {
                    router.push(`/confirmations/requests`)
                  }
                }
              },
            },
            Vue.i18n.translate('default.go_content'),
          );
        },
      }
    );
    if(document.hasFocus()) {
      setTimeout(() => {
        Vue.prototype.$notification.destroy()
      }, 6000);
    }
  },

  openErrorClickable2({state,getters, watch, dispatch}, { message , description, notification_data}){
        Vue.prototype.$notification.error(
            {
                message,
                description,
                duration: 0,
                btn: h => {
                    return h(
                        'b-btn',
                        {
                            props: {
                                variant: 'primary',
                                size: 'sm',
                            },
                            on: {
                                click: (event) => {
                                    event.preventDefault();
                                    if (notification_data.notification_type === 'crm_error') {
                                        router.push(`/settings/crm-configurations`)
                                    }
                                }
                            },
                        },
                        Vue.i18n.translate('default.show_more'),
                    );
                },
            }
        );
    },

    playNotificationSound() {
    let notification_sound = new Audio('/audio/notification.mp3')
    const playSound = () => {
      notification_sound.play()
    };
    playSound()
  },

  openSystemNotification({getters, dispatch},{ title, desc, notification_data} ) {
    if (!("Notification" in window)) {
    } else if (Notification.permission === "granted") {
     
      const notification = new Notification(title, {
        body: desc
      });
      if(notification_data && notification_data.notification_type === 'question') {
          notification.onclick = (e) => {
              e.preventDefault();
              router.push(`/account/${notification_data.integration_id}/${notification_data.account_id}/questions/messages/${notification_data.customer_id}`)
              dispatch("Inbox/updateSeen", notification_data.customer_id, {root: true});
          }
      }
      else if (notification_data && (notification_data.notification_type === 'confirmation_request' || notification_data.notification_type === 'confirmation_restricted_word_request')) {
        notification.onclick = (e) => {
          e.preventDefault(); 
          router.push(`/confirmations/requests`)
        }
      }
      else if (notification_data && (notification_data.notification_type === 'crm_error' )) {
          notification.onclick = (e) => {
              e.preventDefault();
              router.push(`/settings/crm-configurations`)
          }
      }

    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const notification = new Notification(title, {
            body: desc
          });
          if(notification_data && notification_data.notification_type === 'question') {
              notification.onclick = (e) => {
                  e.preventDefault();
                  router.push(`/account/${notification_data.integration_id}/${notification_data.account_id}/questions/messages/${notification_data.customer_id}`)
                  dispatch("Inbox/updateSeen", notification_data.customer_id, {root: true});
              }
          }
          else if (notification_data && (notification_data.notification_type === 'confirmation_request' || notification_data.notification_type === 'confirmation_restricted_word_request')) {
            notification.onclick = (e) => {
              e.preventDefault(); 
              router.push(`/confirmations/requests`)
            }
          }
          else if (notification_data && (notification_data.notification_type === 'crm_error' )) {
              notification.onclick = (e) => {
                  e.preventDefault();
                  router.push(`/settings/crm-configurations`)
              }
          }
        }
      });
    }
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}