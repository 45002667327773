export default {
    "en": {
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "auth": {
            "login": "Login",
            "logout": "Logout",
            "register": "Register",
            "remember_me": "Remember Me",
            "forgot": "Forgot Your Password?",
            "reset_password": "Reset Password",
            "reset_password_desc": "Reset Link",
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "reports": {
            "reports": "Reports",
            "reports_desc": "Reporting can be detailed with date range, accounts, teams and category options",
            "followers": "Followers",
            "following": "Following",
            "mentions": "Mentions",
            "dm": "DM",
            "tracks": "Tracks",
            "incoming": "Incoming",
            "incoming_total": "Incoming Total",
            "outgoing": "Outgoing",
            "outgoing_total": "Outgoing Total",
            "percent": "Percent",
            "external_source": "External Source(Link)",
            "customer_gender": "Customer Genders",
            "process_types": "Process Types",
            "confirmation_results": "Confirmation Results",
            "schedule_results": "Schedule Results",
            "transfer_results": "Transfer Results",
            "total": "Total",
            "both": "Both",
            "primary_categories": "Primary Categories",
            "secondary_categories": "Secondary Categories",
            "sub_categories": "Sub-Categories",
            "selected_status": "Selected Statuses",
            "frequency_map": "Frequency Map",
            "user_touch_performance": "User Touch Performance",
            "team_touch_performance": "Team Touch Performance",
            "user_time_table": "Users Time Table(AVG)",
            "team_time_table": "Team Time Table(AVG)",
            "user_time_performance": "Users Time Performance",
            "team_performance": "Team Performance",
            "best_five": "Best 5",
            "account_activities": "Account Activities",
            "activity": "Activity",
            "first_response": "First Response",
            "first_response_desc": "First Reply/Response Time (FRT) is calculated as the time elapsed between ticket creation and the first reply",
            "first_response_time": "First Response Time",
            "reply_response": "Reply Response",
            "tag_processes": "Tag Processes",
            "risk_heatmap": "Risk Heatmap",
            "interaction_map": "Interaction Map",
            "customers": "Customers",
            "processes": "Processes",
            "selected_team": "Select Team",
            "selected_accounts": "Select Accounts",
            "today": "Today",
            "yesterday": "Yesterday",
            "last_7": "Last 7 Days",
            "last_28": "Last 28 Days",
            "this_month": "This Month",
            "last_month": "Last Month",
            "uniquely": "Uniquely Users => ",
            "minutes": " Minutes",
            "transferred_desc": "The number of unprocessed transferred tickets (Ticket status 'Transferred')",
            "unresolved_desc": "The number of unresolved cases (Ticket status not 'Closed')",
            "solved_desc": "The number of solved cases (Ticket status 'Closed')",
            "open_desc": "The number of open cases (Ticket status 'Open')",
            "no_data": "Data not found",
            "country": "Country",
            "state": "State",
            "county": "County",
            "district": "District",
            "locations": "Locations ",
            "entries": "Entries ",
            "genders": "Gender Ratio ",
            "value": "Value",
            "male": "Male",
            "female": "Female",
            "unknown": "Unknown",
            "accounts": "Accounts",
            "daily": "Daily",
            "weekly": "Weekly",
            "monthly": "Monthly",
            "users": "Users",
            "categories": "Categories",
            "teams": "Teams",
            "status": "Status",
            "emails": {
                "subject": {
                    "daily_report": "Daily Report",
                    "weekly_report": "Weekly Report",
                    "monthly_report": "Monthly Report"
                },
                "content": "Your {time_range} report is generated and attached to this email, You can check it out now."
            }
        },
        "pagination": {
            "previous": "Previous",
            "next": "Next"
        },
        "ticket": {
            "ticket": "Ticket",
            "id": "Ticket ID",
            "status": "Status",
            "asssigned": "Asssigned",
            "created": "Created",
            "updated": "Updated",
            "customer": "Customer",
            "category": "Category",
            "tags": "Tags",
            "tag": "Tag",
            "search": "Search",
            "created_at": "Ticket created date",
            "updated_at": "Ticket updated date",
            "case_id": "Case id",
            "case_id_desc": "Case id is a unique id",
            "case_id_input_desc": "Enter a case id",
            "title": "Title",
            "title_desc": "Ticket title",
            "title_input_desc": "Please, enter a title",
            "desc": "Description",
            "description": "Ticket desc",
            "desc_input_desc": "Please, enter a description",
            "date_start_search": "Search by start date",
            "date_end_search": "Search by end date",
            "location": "Location",
            "ticket_location": "Ticket location",
            "location_search": "Search by location",
            "location_desc": "Search for location",
            "user_search": "Search by user",
            "users_select": "Select user(s)",
            "user_select": "Select users",
            "tag_search": "Search by tags",
            "tag_select": "Select tags",
            "category_search": "Search by category",
            "category_select": "Select categories",
            "category_select_report": "Select category",
            "category_parent_select_desc": "Choose parent category (if exist)",
            "status_open": "Open",
            "status_replied": "Replied",
            "status_transferred": "Transferred",
            "status_awaiting": "Awaiting",
            "status_closed": "Closed",
            "status_unknown": "Unknown",
            "status_null": "Select one",
            "status_search": "Search by status",
            "status_select": "Select statuses",
            "key_search": "Search by key",
            "ticket_search": "Search by ticket",
            "select_users": "Select assigned users",
            "select_teams": "Select assigned teams",
            "schedule": "Schedule",
            "transfer": "Transfer",
            "transfers": "Transfer(s)",
            "transfer_canceled": "Transfer Canceled",
            "canceled_transfers": "Canceled Transfers",
            "active_transfers": "Active Transfers",
            "note": "Note",
            "notes": "Notes",
            "input_note": "Please, enter note for transfer",
            "transfer_to": "Transfer to:",
            "transfer_cancelled": "Transfer cancelled.",
            "transfer_created_by": "Transfer created by:",
            "transfer_broadcast_notify": "{user} has transferred ticket #{case_id} to you/team",
            "transfer_database_notify": "{user} has transferred ticket #{case_id} to you/team",
            "transfer_email_notify_1": "{user} has transferred ticket #{case_id} to you/team.",
            "transfer_canceled_broadcast_notify": "{user} canceled ticket #{case_id} transfer.",
            "transfer_canceled_database_notify": "{user} canceled ticket #{case_id} transfer.",
            "transfer_canceled_email_notify_1": "{user} canceled ticket #{case_id} transfer.",
            "awaiting_transfer": "Awaiting Transfer",
            "transferred": "Transferred",
            "team_transfer": "Team Transfer",
            "user_transfer": "User Transfer",
            "team_user_transfer": "U & T Transfer",
            "user_list": "User(s): ",
            "team_list": "Team(s): ",
            "last_updated": "Last Updated",
            "first_contact": "First Contact",
            "enter_post_id_desc": "Enter Parent ID then press \"Detect\" button",
            "post_id": "Parent ID",
            "ticket_counts": "Ticket Counts",
            "closed_by": "Closed By",
            "close_reason": "Close Reason",
            "close_all": "Close All",
            "ticket_close_confirm": "Are you sure to close all selected Tickets? You won't be able to revert it!",
            "ticket_close_all_confirm": "Are you sure to close all Tickets in the system? You won't be able to revert it!",
            "ticket_edit_confirm": "Are you sure to edit the Ticket? You won't be able to revert it!",
            "ticket_edit_desc": "Are you sure to edit the Ticket? You won't be able to revert it!",
            "change_ticket": "Change Ticket",
            "user_tickets": "User Tickets",
            "user_integrations": "User Integrations(Tasks)",
            "selected_ticket": "Selected Ticket",
            "save_as_new_ticket": "Save as new ticket",
            "mail": {
                "pdf_header": "Ticket Reports",
                "pdf_open_link": "Open link",
                "email_header": "Ticket Reports",
                "email_content": "There are {ticket_count} ticket(s) need to be checked because it has been #{day} days since they were sent.",
                "received_at": "Received at",
                "from": "From",
                "platform": "Platform",
                "account": "Account",
                "status": "Status",
                "statuses": {
                    "1": "Open",
                    "2": "Replied",
                    "3": "Transferred",
                    "4": "Awaiting",
                    "5": "Closed",
                    "6": "Unknown"
                }
            }
        },
        "macros": {
            "title": "Macro",
            "settings": "Settings",
            "category": "Macro category",
            "reply": "Reply",
            "variable": "Macro variable",
            "variables": "Macro variables",
            "image": "Macro image",
            "ticket": "Macros ticket",
            "check_missing": "Please check all required fields!",
            "check_duplicate": "You can't add same variable multiple times!",
            "default": "Default variables",
            "custom": "Custom variables",
            "character_limit": "Character Limits",
            "twitter_mention_limit": "Twitter - Mention Character Limits",
            "twitter_dm_limit": "Twitter - DM Limits",
            "facebook_comment_limit": "Facebook - Comment Character Limits",
            "facebook_messenger_limit": "Facebook - Messenger Character Limits",
            "instagram_comment_limit": "Instagram - Comment Character Limits",
            "max_char_desc": "Maximum characters: {char_limit}.",
            "set_session_messages": "Set Session Messages",
            "session_messages": "Session Messages",
            "set_template_messages": "Set Template Messages",
            "template_messages": "Template Messages",
            "whatsapp_template": "Whatsapp Template",
            "create_template": "Create Template",
            "change_template": "Change Template",
            "template_name": "Template Name",
            "template_type": "Template Type",
            "template_vertical": "Template Labels",
            "template_details": "Template Details",
            "template_variables": "Template Variables",
            "type_details": "Type Details",
            "quick_reply_type": "Quick Reply Template Type",
            "section": "Section",
            "ticket_details": "Ticket Details",
            "buttons": "Buttons",
            "button": "Button",
            "button_type": "Button Type",
            "button_text": "Button Text",
            "autofill_text": "Autofill Text",
            "package_name": "Package Name",
            "signature_hash": "Signature Hash",
            "template_variable": "Variable",
            "template_variable_value": "Variable Value",
            "session_messages_desc": "WhatsApp session messages are the replies that you send to the user within a window of 24 hours from user's most recent message’s time. The content do not require pre-approve from WhatsApp.",
            "template_messages_desc": "WhatsApp template messages are pre-approved messages that you can use to notify users or re-connect with them after the 24-hours window.",
            "whatsapp_image_desc": "WhatsApp vertically crops images with the 1/91: 1 aspect ratio. For best results 800×418 pixels image is recommended.",
            "whatsapp_document_desc": "WhatsApp accepts TXT, PDF, DOC, PPT, XLS types for documents.",
            "whatsapp_audio_desc": "WhatsApp accepts OGG/Opus, AMR, 3GP, AAC, MPEG types for audio files.",
            "whatsapp_video_desc": "WhatsApp accepts MP4, 3GPP types for video files.",
            "whatsapp_sticker_desc": "WhatsApp accepts only WEBP type for stickers. Stickers must be exactly 512x512 pixels.",
            "custom_variable_desc": "Create a distinctive custom variable. E.g. customer.email",
            "template_category_change_desc": "If selected, Meta will automatically update the category of the template as per the template content.",
            "phone_number_desc": "Include country code without any special character. E.g. 905xxxxxxxxx",
            "attach_file": "Attach your file here",
            "change_file": "Change file",
            "header": "Header",
            "header_type": "Header Type",
            "footer": "Footer",
            "body": "Body",
            "body_text": "Body Text",
            "caption": "Caption",
            "file_name": "File Name",
            "birthday": "Birthday",
            "company_title": "Title",
            "add_section": "Add Section",
            "add_list_item": "Add Item",
            "add_button": "Add Button",
            "remove_section": "Remove Section",
            "remove_address": "Remove Address",
            "send_approval": "Send to Approval",
            "optional": "Optional",
            "size_limit_alert": "We can't add files that have higher size than {size_limit} MB",
            "item_limit_alert": "You cannot have more than {item_limit} items",
            "header_desc": "The header text for the message. Maximum characters: {header_limit}.",
            "footer_desc": "The footer text for the message. Maximum characters: {header_limit}.",
            "body_desc": "The body text for the message. Maximum characters: {body_limit}.",
            "button_list_desc": "Allows up to 10 buttons to be embedded in the template.",
            "auth_security_desc": "Add security disclaimer (Optional). E.g. For your security, do not share this code",
            "caption_desc": "The caption text for the message. Maximum characters: {caption_limit}.",
            "expiration_warning": "Expiration warning (Optional). E.g. This code expires in <NUM_MINUTES> minutes.",
            "expiration_desc": "Code expiry time should be between 1 and 90 minutes.",
            "list_global_buttons_title": "Global Button Title",
            "button_description": "It cannot be an empty string. Maximum of {button_limit} characters.",
            "list_items_header": "Interactive List Items",
            "list_items_desc": " Messages including a menu of up to 10 items. This type of message offers a simpler and more consistent way for users to make a selection when interacting with a business.",
            "list_section_title": "Section Title",
            "list_section_subtitle": "Section Subtitle",
            "list_item_title": "Item title",
            "list_item_desc": "Item Description",
            "quick_reply": "Quick Reply",
            "quick_reply_buttons": "Quick Reply Buttons",
            "quick_reply_buttons_desc": "Create up to 3 buttons that let customers respond to your message",
            "quick_reply_button_title": "Quick Reply Button Title",
            "template_elementName_desc": "Name should be unique and can only contain small letters, numbers and underscores",
            "template_vertical_desc": "Define what use-case does this template serves e.g Account update, OTP etc in 2-3 words",
            "send_template_message": "Send Template",
            "select_approved_template": "Please select from approved templates",
            "import_success": "Categories Imported Successfully",
            "import_error": "Problem in Import Categories",
            "removed_template_messages": "Removed Template Messages"
        },
        "user": {
            "password": "Password",
            "password_confirmation": "Password confirmation",
            "password_change": "Change password",
            "login": "Login",
            "logout": "Logout",
            "login_at": "Last login",
            "logout_confirm": "Do you confirm logging out from system?",
            "preffered_message_languages": "Preferred Message Language(s)",
            "ns": "Name Surname"
        },
        "pixel": {
            "tools": "Tools",
            "properties": "Properties",
            "height": "Height",
            "width": "Width",
            "dimension": "Dimension",
            "color": "Color",
            "text": "Text",
            "itext": "IText",
            "textbox": "TextBox",
            "rectangle": "Rectangle",
            "triangle": "Triangle",
            "line": "Line",
            "polygon": "Polygon",
            "draw": "Draw",
            "mode": "Mode",
            "line_width": "Line width",
            "line_color": "Line color",
            "line_shadow": "Line shadow",
            "pencil": "Pencil",
            "circle": "Circle",
            "spray": "Spray",
            "pattern": "Pattern",
            "hline": "Horizonal line",
            "vline": "Vertical line",
            "square": "Square",
            "diamond": "Diamond",
            "texture": "Texture",
            "font": "Font",
            "font_size": "Font size",
            "text_line_height": "Line height",
            "text_char_spacing": "Char spacing",
            "text_align": "Text Align",
            "text_left": "Left",
            "text_center": "Center",
            "text_right": "Right",
            "text_justify": "Justify",
            "text_justify_left": "Justify-left",
            "text_justify_center": "Justify-center",
            "text_justify_right": "Justify-right",
            "text_background_color": "Background text color",
            "align": "Align",
            "pixel": "Pixel",
            "style": "Style",
            "shapes": "Shapes",
            "images": "Images",
            "filters": "Filters",
            "layers": "Layers",
            "empty_filters": "You need to select an object before applying filters.",
            "empty_layers": "There are no layers just yet.",
            "empty_move": "Can't move, no target is selected",
            "empty_align": "Can't align, no target is selected",
            "empty_manage": "Can't manage, no target is selected",
            "sample_text": "Sample Text Goes Here",
            "filter_blur": "Blur",
            "filter_blur_enable": "Enable Blur before applying configuration.",
            "filter_grayscale": "Grayscale",
            "filter_pixelate": "Pixelate",
            "filter_pixelate_pixel": "Pixel",
            "filter_pixelate_enable": "Enable Pixelate before applying configuration.",
            "opacity": "Opacity",
            "shadow": "Shadow",
            "shadow_enable": "Enable Shadow before applying configuration.",
            "offset_x": "Offset X",
            "offset_y": "Offset Y",
            "stroke": "Stroke",
            "stroke_enable": "Enable Stroke before applying configuration.",
            "preview": "Preview",
            "social": "Social",
            "feelings": "Feelings",
            "alert": "For the best usage of Pixel Editor, we recommend using it on tablet or larger devices."
        },
        "confirmation": {
            "title": "Confirmation",
            "request": "Confirmation request",
            "broadcast_notify": "{user}, your confirmation #{id} has been {status}. Confirmation has been reviewed by {reviewed_by} on {reviewed_at}",
            "database_notify": "Your confirmation #{id} has been {status}. Confirmation has been reviewed by {reviewed_by} on {reviewed_at}",
            "email_notify_1": "Your confirmation **{id}** has been **{status}**",
            "email_notify_2": "Confirmation has been reviewed by **{reviewed_by}** on **{reviewed_at}**.",
            "broadcast_request": "{requested_by} have requested your confirmation for #{id} created on {created_at}",
            "database_request": "{requested_by} have requested confirmation for #{id} created on {created_at}",
            "email_request_1": "{requested_by} have requested your confirmation for #**{id}** created on {created_at}",
            "confirmed": "Confirmed",
            "declined": "Declined",
            "declined_and_returned": "Declined and Returned",
            "unknown": "Unknown",
            "require": "Require each reply to be confirmed before posting",
            "related_account": "Related Account",
            "show_full_conversation": "Show Full Conversation",
            "customer_profile_source": "Customer Profile",
            "requested_by": "Requested By",
            "review_by": "Review By",
            "reviewed": "Reviewed",
            "created": "Created",
            "accepted": "Accepted",
            "rejected": "Rejected",
            "waiting": "Waiting",
            "awaiting": "Awaiting",
            "awaiting_confirmation": "Awaiting Confirmation",
            "confirmation_process": "Two-step Confirmation",
            "confirmation_process_desc": "If this option is active, messages cannot be sent without Admin approval",
            "restricted_word_mail_subject": "Restricted Word Detected !! (Confirmation Required)",
            "show_confirmation": "Show confirmation",
            "restricted_word_notify_mail_subject": "Restricted Word Confirmation {status}",
            "restricted_word_title": "Restricted Word Confirmation",
            "restricted_word_broadcast_notify": "Confirmation #{id} has been {status}. Confirmation has been reviewed by {reviewed_by} on {reviewed_at}",
            "restricted_word_broadcast_request": "{requested_by} needs your confirmation for #{id} created on {created_at}",
            "restricted_word_database_notify": "Confirmation #{id} has been {status}. Confirmation has been reviewed by {reviewed_by} on {reviewed_at}",
            "restricted_word_database_request": "{requested_by} needs confirmation for #{id} created on {created_at}"
        },
        "configuration": {
            "toolbar": "Configuration page. Here you can add new accounts",
            "player_mode": "Player Mode  : ",
            "player_desc": "Player Settings",
            "player_mode_desc": "You can set your Player goals",
            "gamification": "Gamification",
            "gamification_desc": "The goal is to answer messages-tickets transferred to each user",
            "inboxzero": "Inbox ZERO",
            "inboxzero_desc": "The goal is to have no unanswered messages-tickets in the message box",
            "gamification_counter": "Gamification Counter : ",
            "gamification_counter_desc": "Maximum number of active tickets that can be assigned to a user",
            "gamification_last_reply_counter": "Gamification Last Reply Counter : ",
            "gamification_last_reply_counter_desc": "Customers can be transferred to the Player who answered it last. Shows the maximum number of answered customers that can be transferred to the Player who previously answered.",
            "warnings": "Warnings",
            "warnings_desc": "Popular accounts and unnatural message density warnings",
            "message_frequency": "Message Frequency",
            "message_frequency_desc": "Instant message frequency in one minute",
            "popular_account": "Popular Account",
            "popular_account_desc": "Set the minimum number of followers for popular account",
            "crm_integration": "CRM",
            "crm_configurations": "CRM Integration",
            "crm_configurations_desc": "Set the configurations of CRM for integration",
            "crm_company_name": "Company Name",
            "crm_company_desc": "Which CRM Company do you want to integrate?",
            "crm_company_name_length": "CRM Company Name must be 5-30 characters long.",
            "crm_company_id": "Company ID",
            "crm_company_id_desc": "Company ID will be provided by your CRM Company, You can get that from them.",
            "crm_company_id_length": "CRM Company ID must be at least 1 characters long.",
            "crm_username": "CRM - Username",
            "crm_username_desc": "CRM username for integration",
            "crm_username_length": "Username must be 5-30 characters long.",
            "crm_pass": "CRM - Password",
            "crm_pass_desc": "CRM Password for integration",
            "crm_pass_length": "Password must be 5-18 characters long.",
            "crm_api_url": "URL",
            "crm_api_url_desc": "CRM connection URL for integration",
            "crm_int_mode": "Integration Mode",
            "crm_int_mode_desc": "CRM Integration Mode - Active/Passive",
            "crm_error_title": "Something went wrong while sending tickets to the CRM. Please contact support with the error below to resolve the issue.",
            "true": "Active",
            "false": "Passive",
            "translation_api_key": "Google Cloud Translation API Key",
            "translation_status": "Service Status",
            "translation_configuration": "Detect Language Configuration",
            "translation_desc": "Detect Language Setting For Incoming Messages",
            "notification_status": "Notification Status",
            "notification_configuration": "Notification Configuration",
            "notification_desc": "Real Time Notifications Settings",
            "whatsapp_configuration": "Whatsapp Configuration",
            "whatsapp_desc": "Whatsapp Notifications Settings",
            "email_configuration": "Email Configuration",
            "email_configuration_smtp": "Email Configuration(SMTP)",
            "email_host": "Email host",
            "email_username": "Email Username",
            "email_password": "Email password",
            "email_port": "Email port",
            "send_for": "Send for",
            "time_range": "Time range",
            "message_type": "Message type",
            "select_integrations": "Select integrations",
            "select_accounts": "Select accounts",
            "ticket_notify": "Ticket Reminder Configuration",
            "ticket_notify_desc": "Reminder settings for selected ticket statuses",
            "ticket_notify_status": "Reminder Status",
            "ticket_notify_cycle": "Reminder Cycles",
            "ticket_notify_cycle_day": "Day(s) later",
            "ticket_notify_cycle_hour": "At selected time",
            "report_notify": "Report Automation Configuration",
            "report_notify_desc": "Settings for Report Automation"
        },
        "customer": {
            "title": "Customer",
            "identification": "Identification",
            "identification_desc": "Passport number",
            "id": "Id",
            "id_desc": "Customer id",
            "username": "Username",
            "username_desc": "Customer username",
            "name": "Name",
            "name_desc": "Customer name",
            "surname": "Surname",
            "surname_desc": "Customer surname",
            "phone": "Phone",
            "phones": "Phones",
            "phone_desc": "Customer phone",
            "email": "Email",
            "emails": "Emails",
            "email_desc": "Customer email",
            "gender": "Gender",
            "genders": "Genders",
            "gender_desc": "Gender",
            "gender_man": "Man",
            "gender_woman": "Woman",
            "gender_unknown": "Unkown",
            "gender_search": "Search by gender",
            "gender_select": "Select gender",
            "type": "Type",
            "history": "Changes history",
            "no_history": "No changes detected",
            "type_desc": "Customer type",
            "type_select": "Type",
            "about": "About",
            "statuses": "Statuses",
            "media_count": "Posts",
            "verified": "Verified",
            "protected": "protected",
            "following": "Following",
            "followers": "Followers",
            "url": "Url",
            "urls": "Urls",
            "block": "Block Customer",
            "block_twitter": "Block (Twitter)",
            "block_confirm": "Do you want to **\"BLOCK\"** this customer from social media?",
            "block_success": "The customer successfuly blocked",
            "block_verify": "Yes, Block it",
            "moved_to_trash": "Customer moved to Trash",
            "trash_restored": "Customer restored from Trash",
            "trash_created_by": "Moved to trash By =>",
            "trash_restored_by": "Restored By:",
            "location": "Location",
            "customer_location": "Customer location",
            "no_location": "No location detected",
            "country": "Country",
            "country_code": "Country Code",
            "postal_code": "Postal Code",
            "state_code": "State",
            "county": "County",
            "district": "District",
            "address": "Address",
            "addresses": "Addresses",
            "city": "City",
            "street": "Street",
            "work": "Work",
            "company": "Company",
            "department": "Department",
            "unread_count": "Unread Message(s)",
            "active_tickets": "Active Ticket(s)",
            "assigned_by": "Assigned by",
            "unassigned": "Unassign",
            "is_replied_by": "Is Being Replied By {user_name}",
            "customer_type": "Customer Type",
            "customer_gender": "Customer Gender",
            "whatsapp_session_alert": "It's been more than 24 hours since the last customer message. According to WhatsApp guidelines, post the expiry of the 24hr session window from the last customer message, the business cannot respond to the end-user without using approved message templates."
        },
        "simple": {
            "email": "E-Mail Address",
            "email_not_verified": "Validation failed! Please check your email configuration",
            "email_verified": "E-Mail Address/Password Validated",
            "send_test_email": "Test E-Mail",
            "password": "Password",
            "password_confirmation": "Confirm Password",
            "name": "Name",
            "surname": "Surname",
            "phone": "Phone",
            "phone_number": "Phone Number",
            "role": "Role",
            "display_name": "Display name",
            "type_to_search": "Type to search",
            "clear": "Clear",
            "username": "Username",
            "accounts": "Accounts",
            "select_account": "Select Account(s)",
            "account": "Account",
            "service_provider": "API Service Provider",
            "users": "Users",
            "user": "User",
            "team": "Team",
            "super": "Super Admin",
            "save": "Save",
            "ok": "Ok",
            "history": "History",
            "post": "Post",
            "add": "Add",
            "edit": "Edit",
            "file": "File",
            "view": "View",
            "cancel": "Cancel",
            "confirm": "Confirm",
            "select": "Select",
            "remove": "Remove",
            "update": "Update",
            "updated": "Updated",
            "created": "Created",
            "created_at": "Created at",
            "status": "Status",
            "running": "Running",
            "failing": "Failing",
            "send_failed": "Send Failed!",
            "actions": "Actions",
            "integration": "Integration",
            "integration_info": "You will be redirected to external site where you will need to authorize our application!",
            "success": "Success",
            "success_info": "Your request has been successfully sent!",
            "title": "Title",
            "desc": "Description",
            "content": "Content",
            "login_with_fb": "Login with Facebook",
            "type": "Type",
            "color": "Color",
            "important": "Important",
            "vip": "VIP",
            "example": "Example",
            "yes": "Yes",
            "no": "No",
            "records_not_found": "Records not found",
            "activate": "Activate",
            "date": "Date",
            "disable": "disable",
            "delete": "Delete",
            "delete_confirm": "You won't be able to revert this!",
            "delete_button": "Yes, delete it!",
            "deleted": "Deleted",
            "deleted_info": "Deleted message",
            "deleted_confirmed": "Record has been successfully deleted.",
            "parent": "Parent",
            "error": "Error",
            "error_info": "Something went wrong! Please check logs!",
            "connect": "Connect",
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "close": "OK, Close",
            "awaiting": "Awaiting",
            "unresolved": "Unresolved",
            "solved": "Solved",
            "track": "Track",
            "track_info": "Hashtags starts with \"#\", mentions starts with \"@\"",
            "permission": "Permission",
            "message_delete": "Delete message",
            "message_deleted": "Deleted this message",
            "unknown": "Unknown",
            "scheduled": "Scheduled",
            "message_delete_confirm": "Are you sure to *\"Delete\"* this message?",
            "delete_verify": "Yes,remove it",
            "parent_post": "Parent Post",
            "sent": "Sent",
            "canceled": "Cancelled",
            "map": "Map",
            "maps": "Maps",
            "view_profile": "View Profile",
            "hide": "Hide",
            "unhide": "Unhide",
            "message_hide": "Hide the message",
            "message_unhide": "Unhide the message",
            "hide_verify": "Yes, hide it",
            "unhide_verify": "Yes, Unhide it",
            "hidden_info": "Hidden message",
            "message_hide_confirm": "Are you sure to *\"Hide\"* this message?",
            "message_unhide_confirm": "Are you sure to *\"Unhide\"* this message?",
            "popular_email_notify": "{customer} (Popular Account) sent you message on @{account_username} {integration_id} account.",
            "vip_email_notify": "{customer} (VIP Account) sent you message on @{account_username} {integration_id} account.",
            "message_frequency_email_notify": "{account_name} ({account_username}) {integration_id} account reached incoming message frequency count!",
            "checked_at": "Checked at",
            "message_count": "Message count",
            "platform": "Platform",
            "email_notification": "Email Notification",
            "whatsapp_notification": "Whatsapp Notification",
            "report_notification": "Report Notification",
            "language": "Language",
            "left_characters": "Characters Left",
            "detect": "Detect",
            "crm": "CRM",
            "sent_as": "Sent as",
            "add_message": "Add Message",
            "add_message_by_url": "Add message by URL",
            "add_users": "Add Users",
            "delete_users": "Delete Selected Users",
            "edit_users": "Edit Users",
            "url": "URL",
            "add_url_desc": "Copy tweet, mention or comment URL here.",
            "sender": "Sender",
            "this_message_exist": "This Message exist.",
            "api_token": "API Token",
            "message": "Message",
            "agent": "Agent",
            "crm_show_name": "Show name on CRM messages",
            "activate_pagination": "Activate Pagination",
            "deactivate_pagination": "Deactivate pagination",
            "new_message_email_notify": "New message arrived for {account_name} ({account_username}) {integration_id} account!",
            "new_message_arrived": "New message arrived",
            "crm_error_subject": "CRM System Error: Action Required",
            "received_at": "Received at",
            "from": "From",
            "to": "To",
            "default": "Default",
            "twitter_connect": "Connect to Twitter",
            "twitter_connect_desc": "Due to changes in Twitter(X)'s system, please authorize all of the applications below to have a seamless experience.",
            "twitter_connect_auth": "Authorize twitter account",
            "total_tweet_count": "Total Tweet Count",
            "whatsapp_attachment_duration_error": "Cannot add files that are less than {seconds} seconds!",
            "edit_whatsapp_profile": "Edit WhatsApp Profile",
            "recipients": "Recipients",
            "delivered": "Delivered",
            "read": "Read",
            "create_campaign": "Create Campaign",
            "edit_campaign": "Edit Campaign",
            "delete_campaign": "Delete Campaign",
            "start_campaign": "Start Campaign",
            "campaign_details": "Campaign Details",
            "campaign_name": "Campaign Name",
            "campaign_id": "Campaign Id",
            "campaign_overview": "Campaign Overview",
            "campaign_schedule_desc": "Campaign set to be posted at: {schedule_at}",
            "campaign": "Campaign",
            "campaigns": "Campaigns",
            "campaigns_desc": "Here you can manage, edit or remove Whatsapp campaigns",
            "whatsapp_campaigns": "WhatsApp Campaigns",
            "audience": "Audience",
            "fallback_value": "Fallback Value",
            "back": "Back",
            "send": "Send",
            "send_now": "Send Now",
            "audience_col_desc": "'phone_number' column is mandatory.",
            "audience_phone_desc": "Phone number should have country code as prefix and no '+' symbol. E.g. 905551234567",
            "audience_auth": "I confirm that the contacts uploaded have agreed to receive this communication",
            "test_email": "This is a test mail",
            "test_email_content": "This is a test mail content",
            "message_sent": "Message sent successfully",
            "host_problem": "Host Problem",
            "username_problem": "Username Problem",
            "password_problem": "Password Problem",
            "other_problem": "Something wrong",
            "open_link": "Open link",
            "show_preferred_lang_messages": "Show preferred language messages",
            "show_all_lang_messages": "Show all language messages",
            "import": "Import",
            "download_simple_file": "Download Sample File",
            "deleted_at": "Deleted At"
        },
        "schedule": {
            "sent": "Sent",
            "cancelled": "Cancelled",
            "awaiting": "Awaiting",
            "date_range": "Select date range",
            "scheduled_at": "scheduled at: ",
            "pending": "Pending",
            "schedule_desc": "Reply set to be posted at: {schedule_at}"
        },
        "page": {
            "homepage": "Home Page",
            "questions": "Questions",
            "answers": "Answers",
            "transfers": "Transfers",
            "transfers_desc": "One to one transfers",
            "confirmations_list": "Confirmations list",
            "confirmations_history": "Confirmation History",
            "confirmations": "Confirmations",
            "confirmations_desc": "Here you can approve or deny confirmation requests",
            "confirmations_history_desc": "Here you can see confirmation requests history",
            "conf_note": "Confirmation Note",
            "content": "Content",
            "attachment": "Attachment",
            "attachments": "Attachments",
            "inbox": "Inbox",
            "inbox_desc": "Inbox where all messages are coming",
            "archive": "Archive",
            "tickets": "Tickets",
            "spam": "Spam",
            "trash": "Trash",
            "tracks": "Tracks",
            "tracks_desc": "Here you can manage your tracked hashtags and accounts",
            "tracks_limit": "You exceeded Tracks limit , Please delete or change current tracks.",
            "tracks_remaining": "Tracks Remaining",
            "mutes": "Mutes",
            "mutes_desc": "Here you can remove any account mutes",
            "blocks": "Blocks",
            "blocks_desc": "Here you can remove any account blocks",
            "management": "Management",
            "configurations": "Configuration",
            "configurations_desc": "Here you can configure your accounts activities",
            "accounts": "Accounts",
            "accounts_desc": "Here you can add, edit or remove accounts",
            "announcements": "Announcements",
            "announcements_desc": "Here you can add, edit or remove announcements",
            "categories": "Categories",
            "categories_desc": "Here you can add, edit or remove categories",
            "categories_select_desc": "Choose category (if exist)",
            "categorytypes": "Category Types",
            "categorytypes_desc": "Here you can add, edit or remove category types",
            "customertypes": "Customer Types",
            "customertypes_desc": "Here you can add, edit or remove customer types",
            "customer_genders": "Customer Genders",
            "customer_genders_desc": "Here you can add, edit or remove customer genders",
            "gallery": "Gallery",
            "gallery_desc": "Here you can manage uploaded images",
            "integrations": "Integrations",
            "integrations_desc": "Here you can activate any integrations you would need",
            "tags": "Tags",
            "tags_desc": "Here you can add, edit or remove tags",
            "macros": "Macro",
            "macros_desc": "Here you can add, edit or remove macros",
            "macros_categories": "Macro categories",
            "macros_categories_desc": "Here you can add, edit or remove macros categories",
            "users": "Users",
            "users_desc": "Here you can add, edit or remove users",
            "roles": "Roles",
            "roles_desc": "Here you can add, edit or remove roles",
            "teams": "Teams",
            "teams_desc": "Here you can add, edit or remove team",
            "notifications": "Notifications",
            "notifications_desc": "Here you can check your recent notifications",
            "comments": "comments",
            "new": "New",
            "player": "Player",
            "crm_configurations": "CRM Configurations",
            "crm_configurations_desc": "Here you can configure your CRM integration details",
            "search": "Search",
            "settings": "Settings",
            "search_questions_desc": "Here you can search questions",
            "search_answer_desc": "Here you can search answers",
            "search_tickets_desc": "Here you can search tickets",
            "search_customers_desc": "Here you can search customers",
            "upload_file": "Upload your file...",
            "upload_file_check": "Upload your file( Max {size_limit} )",
            "variables_edit": "Variables Edit",
            "close_tickets": "Close Tickets",
            "close_ticket": "Close Ticket",
            "close_tickets_desc": "Here you can close all open tickets with Post ID"
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "accepted_if": "The {attribute} must be accepted when {other} is {value}.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} must only contain letters.",
            "alpha_dash": "The {attribute} must only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} must only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} must not be greater than {max}.",
                "file": "The {attribute} must not be greater than {max} kilobytes.",
                "string": "The {attribute} must not be greater than {max} characters.",
                "array": "The {attribute} must not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "multiple_of": "The {attribute} must be a multiple of {value}.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": "The password is incorrect.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid timezone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} must be a valid URL.",
            "uuid": "The {attribute} must be a valid UUID.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        },
        "default": {
            "naming": "Default",
            "hi": "Hi",
            "hi_user": "Hi {user}",
            "welcome": "Welcome",
            "back": "Back",
            "thanks": "Thanks",
            "user": "User",
            "users": "Users",
            "team": "Team",
            "teams": "Teams",
            "save": "Save",
            "cancel": "Cancel",
            "reset": "Reset",
            "reset_desc": "Reset all fields",
            "reset_note": "Reset note",
            "reset_note_desc": "Clear note area",
            "reply": "Reply",
            "reply_desc": "Write a message",
            "add_note": "Add note",
            "close": "Close",
            "add": "Add",
            "edit": "Edit",
            "json": "Add json file",
            "remove": "Remove",
            "delete": "Delete",
            "preview": "Preview",
            "category": "Category",
            "note": "Note",
            "select": "Select",
            "image": "Image",
            "images": "Images",
            "upload": "Upload",
            "status": "Status",
            "active": "Active",
            "inactive": "Inactive",
            "refresh": "Refresh",
            "disable": "Disable",
            "disabled": "Disabled",
            "enable": "Enable",
            "enabled": "Enabled",
            "email": "E-Mail Address",
            "password": "Password",
            "password_confirmation": "Confirm Password",
            "name": "Name",
            "surname": "Surname",
            "desc": "Description",
            "actions": "Actions",
            "mute": "Mute",
            "blacklist": "Blacklist",
            "login_description": "Social networks under control!",
            "select_instagram_account": "Select instagram account",
            "select_instagram_account_desc": "Please choose which instagram account you want to add in",
            "select_facebook_account": "Select facebook account",
            "select_facebook_account_desc": "Please choose which facebook account you want to add in",
            "select_linkedin_account": "Select LinkedIn account",
            "select_linkedin_account_desc": "Please choose which LinkedIn account you want to add in",
            "reopen": "Reopen",
            "load_more": "Load more",
            "current_usage": "Current usage",
            "change": "Change",
            "twitter_limit_notify": "Total Tweet count reached {tweet_count}! Current limit situation: {limit}/{tweet_count}",
            "twitter_limit_title": "Twitter Tweet Limit",
            "twitter_limit_ended": "You reached your Tweet limit! Contact the support to increase your limit and start to fetching tweets again.",
            "twitter_limit_near_ending": "Tweet limit almost reached! Contact the support to increase your limit to avoid service disruption and don't stop fetching tweets.",
            "twitter_dm_limit": "Daily Twitter(X) DM limit:",
            "twitter_dm_limit_desc": "Twitter DM limit will reset at {reset_time}.",
            "twitter_dm_limit_alert": "You reached your daily DM limit. Remaining Limit: {remaining_limit}. Your DM will be sent after {reset_time}",
            "video": "Video",
            "business_desc": "Business Description",
            "business_details": "Business Details",
            "contact_info": "Contact Information",
            "edit_whatsapp_profile": "Edit Whatsapp Business Profile",
            "whatsapp_picture_desc": "Whatsapp profile picture should be between {min} - {min} and {max} - {max} dimensions. Maximum file size is: {file_size} MB",
            "website": "Website",
            "new_message_arrived": "You have a new message!",
            "new_message_broadcast": "New Message from {customer} received: {content}",
            "new_popular_message_arrived": "You have a new message from Popular Customer!",
            "new_popular_message_broadcast": "New Message from Popular {customer} received: {content}",
            "new_vip_account_message_arrived": "You have a new message from VIP Account Customer!",
            "new_vip_account_message_broadcast": "New Message from VIP Account {customer} received: {content}",
            "go_content": "Go to Content",
            "show_more": "Show More",
            "import_category": "Import Category"
        },
        "permission": {
            "add": "Add",
            "edit": "Edit",
            "view": "View",
            "delete": "Delete",
            "denied": "You don't have permission for this.",
            "read-transfer": "You don't have \"read-transfer\" permission.",
            "update-transfer": "You don't have \"update-transfer\" permission.",
            "read_users": "You don't have \"read-users\" permission.",
            "update_users": "You don't have \"update-users\" permission.",
            "delete_users": "You don't have \"delete-users\" permission.",
            "read_teams": "You don't have \"read-teams\" permission.",
            "update_teams": "You don't have \"update-teams\" permission.",
            "delete_teams": "You don't have \"delete-teams\" permission.",
            "create_teamuser": "You don't have \"create-teamuser\" permission.",
            "update_teamuser": "You don't have \"update-teamuser\" permission.",
            "create_roles": "You don't have \"create-roles\" permission.",
            "read_roles": "You don't have \"read-roles\" permission.",
            "update_roles": "You don't have \"update-roles\" permission.",
            "delete_roles": "You don't have \"delete-roles\" permission."
        },
        "player": {
            "player": "PLAYER(All In One)",
            "play_mode": "Play Mode",
            "play": "Play",
            "pause": "Pause"
        }
    },
    "tr": {
        "passwords": {
            "password": "Şifreler en az altı karakter olmalı ve onayı ile eşleşmelidir.",
            "reset": "Şifreniz sıfırlandı!",
            "sent": "Parola sıfırlama bağlantınızı e-posta ile gönderdik!",
            "token": "Bu şifre sıfırlama jetonu geçersiz.",
            "user": "Bu e-posta adresine sahip bir kullanıcı bulunamadı."
        },
        "auth": {
            "login": "Giriş",
            "logout": "Çıkış",
            "register": "Kayıt",
            "remember_me": "Beni Hatırla",
            "forgot": "Parolanızı mı unuttunuz?",
            "reset_password": "Şifreyi Yenile",
            "reset_password_desc": "Şifre Sıfırlama Bağlantısını Gönder",
            "failed": "Bu kimlik bilgileri kayıtlarımızla eşleşmiyor.",
            "throttle": "Çok fazla giriş denemesi yapıldı. Lütfen {seconds} saniye sonra tekrar deneyin."
        },
        "reports": {
            "reports": "Raporlar",
            "reports_desc": "Raporlama, tarih aralığı, hesaplar, takımlar ve kategori seçenekleri ile detaylandırılabilir",
            "followers": "Takipçi",
            "following": "Takip",
            "mentions": "Mentionlar",
            "dm": "DM",
            "tracks": "Keyler",
            "incoming": "Gelen",
            "incoming_total": "Toplam Gelen",
            "outgoing": "Giden",
            "outgoing_total": "Toplam Giden",
            "percent": "Yüzde",
            "external_source": "Harici Kaynak(Link)",
            "customer_gender": "Müşteri Cinsiyetleri",
            "process_types": "İşlem Türleri",
            "confirmation_results": "Onaylama Sonuçları",
            "schedule_results": "Planlama Sonuçları",
            "transfer_results": "Transfer Sonuçları",
            "total": "Toplam",
            "both": "Kesişim",
            "primary_categories": "Birincil Kategoriler",
            "secondary_categories": "İkincil Kategoriler",
            "sub_categories": "Alt Kategoriler",
            "selected_status": "Kategori Durumları",
            "frequency_map": "Frekans Haritası",
            "user_touch_performance": "Kullanıcı Cevap Performansı",
            "team_touch_performance": "Takım Cevap Performansı",
            "user_time_table": "KUllanıcı Zaman Tablosu(ORT)",
            "team_time_table": "Takım Zaman Tablosu(ORT)",
            "user_time_performance": "Kullanıcı Zaman performansları",
            "team_performance": "Takım Performansı",
            "best_five": "En İyi 5",
            "account_activities": "Hesap Aktiviteleri",
            "activity": "Aktivite",
            "first_response": "İlk Cevap",
            "first_response_desc": "İlk Cevap/Yanıtlama Süresi (İYS), ticket oluşturma ile ilk cevap arasında geçen süre olarak hesaplanır",
            "first_response_time": "İlk Cevap Süresi",
            "tag_processes": "Etiket Süreçleri",
            "risk_heatmap": "Risk Sıcaklık Haritası",
            "interaction_map": "Etkileşim Haritası",
            "customers": "Müşteriler",
            "processes": "İşlemler",
            "selected_team": "Takım Seç",
            "selected_accounts": "Hesap Seç",
            "today": "Bugün",
            "yesterday": "Dün",
            "last_7": "Son 7 Gün",
            "last_28": "Son 28 Gün",
            "this_month": "Bu Ay",
            "last_month": "Geçen Ay",
            "uniquely": "Benzersiz Kullanıcılar => ",
            "minutes": " Dakikalar",
            "transferred_desc": "İşlenme tabi tutulmamış transfer edilen ticketların sayısı (Ticket durumu 'Aktarılan')",
            "unresolved_desc": "Çözüme kavuşmamış vaka sayısı (Ticket durumu 'Kapalı' olmayan)",
            "solved_desc": "Çözüme kavuşmuş vaka sayısı (Ticket durumu 'Kapalı')",
            "open_desc": "Açık vaka sayısı (Ticket durumu 'Açık')",
            "no_data": "Uygun veri bulunamadı",
            "country": "Ülke",
            "state": "Şehir",
            "county": "İlçe",
            "district": "Mahalle",
            "locations": "Lokasyonlar ",
            "entries": "Kayıtlar ",
            "genders": "Cinsiyet Oranları ",
            "value": "Adet",
            "male": "Erkek",
            "female": "Kadın",
            "unknown": "Bilinmeyen",
            "accounts": "Hesaplar",
            "daily": "Günlük",
            "weekly": "Haftalık",
            "monthly": "Aylık",
            "users": "Kullanıcılar",
            "categories": "Kategoriler",
            "teams": "Takımlar",
            "status": "Durum",
            "emails": {
                "subject": {
                    "daily_report": "Daily Report",
                    "weekly_report": "Weekly Report",
                    "monthly_report": "Monthly Report"
                },
                "content": "Your {time_range} report is generated and attached to this email, You can check it out now."
            }
        },
        "pagination": {
            "previous": "Önceki",
            "next": "Sonraki"
        },
        "ticket": {
            "ticket": "Ticket",
            "id": "Ticket No",
            "status": "Durum",
            "asssigned": "Atandı",
            "created": "Oluşturuldu",
            "updated": "Güncellendi",
            "customer": "Müşteri",
            "category": "Kategori",
            "tags": "Etiketler",
            "tag": "Etiketler ::: ",
            "search": "Arama",
            "created_at": "Ticket oluşturulma zamanı",
            "updated_at": "Ticket güncellenme zamanı",
            "case_id": "Vaka no",
            "case_id_desc": "Vaka numarası benzersiz bir numaradır",
            "case_id_input_desc": "Bir vaka numarası girin",
            "title": "Konu",
            "title_desc": "Ticket'ın konusu",
            "title_input_desc": "Lütfen bir konu girin",
            "desc": "Açıklama",
            "description": "Ticket açıklaması",
            "desc_input_desc": "Lütfen bir açıklama girin",
            "date_start_search": "Başlangıç tarihine göre ara",
            "date_end_search": "Bitiş tarihine göre ara",
            "location": "Lokasyon",
            "ticket_location": "Ticket Lokasyonu",
            "location_search": "Lokasyon ara",
            "location_desc": "Lokasyon bazlı arama",
            "user_search": "Kullanıcı ara",
            "users_select": "Kullanıcıları seç",
            "user_select": "Kullanıcı seç",
            "key_search": "Kelime ara",
            "ticket_search": "Ticket ara",
            "tag_search": "Etiket ara",
            "tag_select": "Etiket seç",
            "category_search": "Kategori ara",
            "category_select": "Kategori seç",
            "category_select_report": "Kategori seçimi",
            "category_parent_select_desc": "Üst kategori seçin (varsa)",
            "status_open": "Açık",
            "status_replied": "Cevaplandı",
            "status_transferred": "Transfer",
            "status_awaiting": "Beklemede",
            "status_closed": "Kapalı",
            "status_unknown": "Bilinmiyor",
            "status_null": "Birini Seçin",
            "status_search": "Durum ara",
            "status_select": "Durum seç",
            "select_users": "Transfer kullanıcısı seçin",
            "select_teams": "Transfer takımı seçin",
            "schedule": "Planlama",
            "transfer": "Transfer",
            "transfers": "Transfer(ler)",
            "transfer_canceled": "Transfer silindi",
            "canceled_transfers": "Canceled Transfers",
            "active_transfers": "Aktif Transferler",
            "note": "Not",
            "notes": "Notlar",
            "input_note": "Transfer notu giriniz",
            "transfer_to": "Transfer:",
            "transfer_cancelled": "Transfer silindi.",
            "transfer_created_by": "Transfer Eden:",
            "transfer_broadcast_notify": "{user}, #{case_id} nolu Ticket'ı sana/takımına transfer edildi.",
            "transfer_database_notify": "{user}, #{case_id} nolu Ticket'ı sana/takımına transfer edildi.",
            "transfer_email_notify_1": "{user}, #{case_id} nolu Ticket'ı sana/takımına transfer edildi.",
            "transfer_canceled_broadcast_notify": "{user}, #{case_id} nolu Ticket transferini iptal etti",
            "transfer_canceled_database_notify": "{user}, #{case_id} nolu Ticket transferini iptal etti",
            "transfer_canceled_email_notify_1": "{user}, #{case_id} nolu Ticket transferini iptal etti.",
            "awaiting_transfer": "Bekleyen Transfer",
            "transferred": "Transferde",
            "team_transfer": "Takıma Transfer",
            "user_transfer": "Kullanıcıya Transfer",
            "team_user_transfer": "K & T Transfer",
            "user_list": "Kullanıcı(lar): ",
            "team_list": "Takım(lar): ",
            "last_updated": "Son Güncelleme",
            "first_contact": "İlk Temas",
            "enter_post_id_desc": "Parent ID'sini girdikten sonra \"Çalıştır\"'a tıklayınız",
            "post_id": "Parent ID",
            "ticket_counts": "Ticket Numarası",
            "closed_by": "Kullanıcı",
            "close_reason": "Açıklama",
            "close_all": "Hepsini Kapat",
            "ticket_close_confirm": "Seçili Ticket'ları kapatmak istediğinize emin misiniz? (Bu işlem geri alınamaz!)",
            "ticket_close_all_confirm": "Sistemdeki bütün Ticket'ları kapatmak istediğinize emin misiniz? (Bu işlem geri alınamaz!)",
            "ticket_edit_confirm": "Ticket'ı düzenlemek istediğinize emin misiniz? (Bu işlem geri alınamaz!)",
            "ticket_edit_desc": "Seçtiğiniz Ticket'ı düzenlemek istediğinize emin misiniz? (Bu işlem geri alınamaz!)",
            "change_ticket": "Ticket Değiştir",
            "user_tickets": "Ticket Listesi",
            "user_integrations": "Entegrasyon Listesi",
            "selected_ticket": "Seçili Ticket",
            "save_as_new_ticket": "Save as new ticket",
            "mail": {
                "pdf_header": "Ticket Reports",
                "pdf_open_link": "Open link",
                "email_header": "Ticket Reports",
                "email_content": "There are {ticket_count} ticket(s) need to be checked because it has been #{day} days since they were sent.",
                "received_at": "Received at",
                "from": "From",
                "platform": "Platform",
                "account": "Account",
                "status": "Status",
                "statuses": {
                    "1": "Open",
                    "2": "Replied",
                    "3": "Transferred",
                    "4": "Awaiting",
                    "5": "Closed",
                    "6": "Unknown"
                }
            }
        },
        "macros": {
            "title": "Şablon",
            "settings": "Ayarlar",
            "category": "Şablon Kategorisi",
            "reply": "Cevap",
            "variable": "Şablon Değişkeni",
            "variables": "Şablon Değişkenleri",
            "image": "Şablon Görseli",
            "ticket": "Ticket Şablonu",
            "check_missing": "Lütfen tüm gerekli alanları kontrol edin!",
            "check_duplicate": "Aynı değişkeni birden çok kez ekleyemezsiniz!",
            "default": "Varsayılan değişkenler",
            "custom": "Özel değişkenler",
            "character_limit": "Karakter Sayacı",
            "twitter_mention_limit": "Twitter - Mention Karakter Limiti",
            "twitter_dm_limit": "Twitter - DM Limiti",
            "facebook_comment_limit": "Facebook - Comment Karakter Limiti",
            "facebook_messenger_limit": "Facebook - Messenger Karakter Limiti",
            "instagram_comment_limit": "Instagram - Comment Karakter Limiti",
            "max_char_desc": "Maksimum karakter: {char_limit}.",
            "set_session_messages": "Hazır Oturum Mesajları",
            "session_messages": "Hazır Oturum Mesajları",
            "set_template_messages": "Hazır Şablon Mesajları",
            "template_messages": "Hazır Şablon Mesajları",
            "whatsapp_template": "Whatsapp Şablon",
            "create_template": "Şablon Oluştur",
            "change_template": "Şablonu Değiştir",
            "template_name": "Şablon Adı",
            "template_type": "Şablon Tipi",
            "template_vertical": "Şablon Etiketleri",
            "template_details": "Şablon Detayları",
            "template_variables": "Şablon değişkenleri",
            "type_details": "Şablon Seçim Detayları",
            "quick_reply_type": "Quick Reply Şablon Tipi",
            "section": "Grup",
            "ticket_details": "Ticket Detayları",
            "buttons": "Butonlar",
            "button": "Buton",
            "button_type": "Buton Tipi",
            "button_text": "Buton Metni",
            "autofill_text": "Otomatik Doldurma Metni",
            "package_name": "Paket Adı",
            "signature_hash": "Signature Hash",
            "template_variable": "Değişken",
            "template_variable_value": "Değişken değeri",
            "session_messages_desc": "WhatsApp oturum mesajları, kullanıcının en son mesajından itibaren 24 saat içinde kullanıcıya gönderdiğiniz yanıtlardır. İçerik WhatsApp'tan ön onay gerektirmez.",
            "template_messages_desc": "WhatsApp şablon mesajları, 24 saatlik sürenin dışında, kullanıcıları bilgilendirmek veya onlarla yeniden bağlantı kurmak için kullanabileceğiniz, öncesinde WhatsApp tarafından onaylanmış mesajlardır.",
            "whatsapp_img_desc": "WhatsApp, resimleri 1/91: 1 en boy oranıyla dikey olarak kırpar. En iyi sonuçlar için 800×418 piksel resim önerilir.",
            "whatsapp_document_desc": "WhatsApp dökümanlar için TXT, PDF, DOC, PPT, XLS dosya türlerini kabul eder.",
            "whatsapp_audio_desc": "WhatsApp ses dosyaları için OGG/Opus, AMR, 3GP, AAC, MPEG dosya türlerini kabul eder.",
            "whatsapp_video_desc": "WhatsApp video dosyaları için MP4, 3GPP dosya türlerini kabul eder.",
            "whatsapp_sticker_desc": "WhatsApp sticker için sadece WEBP dosya türünü kabul eder. Çıkartmalar tamı tamına 512x512 piksel boyutunda olmalıdır.",
            "custom_variable_desc": "Ayırt edici bir değişken oluşturun. Örneğin, customer.email",
            "template_category_change_desc": "Eğer seçili ise, Meta şablonun kategorisini şablon içeriğine göre otomatik olarak güncelleyecektir.",
            "phone_number_desc": "Herhangi bir özel karakter olmadan ülke kodunu ekleyin. Örneğin. 905xxxxxxxxx",
            "attach_file": "Dosyanızı buraya ekleyin",
            "change_file": "Dosyayı değiştir",
            "header": "Başlık",
            "header_type": "Başlık Tipi",
            "footer": "Altbilgi",
            "body": "Gövde",
            "body_text": "Gövde Metni",
            "caption": "Alt Metin",
            "file_name": "Dosya Adı",
            "birthday": "Doğum Günü",
            "company_title": "Ünvan",
            "add_section": "Grup Ekle",
            "add_list_item": "Seçenek Ekle",
            "add_button": "Buton Ekle",
            "remove_section": "Grubu Çıkar",
            "remove_address": "Adresi Çıkar",
            "send_approval": "Onaya Gönder",
            "optional": "İsteğe Bağlı",
            "size_limit_alert": "Boyutu {size_limit} MB'den yüksek olan dosyaları ekleyemiyoruz.",
            "item_limit_alert": "{item_limit} değerinden daha fazla öğeye sahip olamazsınız",
            "header_desc": "Mesajın başlık metni. Maksimum karakter: {header_limit}.",
            "footer_desc": "Mesajın alt bilgi metni. Maksimum karakter: {header_limit}.",
            "body_desc": "Mesaj için gövde metni. Maksimum karakter: {body_limit}.",
            "button_list_desc": "Şablona en fazla 10 buton yerleştirilebilir",
            "auth_security_desc": "Güvenlik reddi beyanı ekleyin (İsteğe bağlı). Örneğin. Güvenliğiniz için bu kodu paylaşmayın",
            "caption_desc": "Mesajın alt başlık metni. Maksimum karakter: {caption_limit}.",
            "expiration_warning": "Son kullanma uyarısı (İsteğe bağlı). Örneğin. Bu kodun süresi <NUM_MINUTES> dakika içinde dolacak.",
            "expiration_desc": "Kodun geçerlilik süresi 1 ile 90 dakika arasında olmalıdır.",
            "list_global_buttons_title": "Liste Ana Buton Başlığı",
            "button_description": "Boş bırakılamaz. Maksimum karakter: {button_limit}.",
            "list_items_header": "Etkileşimli Liste Öğeleri",
            "list_items_desc": " Toplam 10 seçeneğe kadar eklenebilir menu içeren mesaj türü. Bu tür mesajlar, kullanıcılara bir işletmeyle etkileşimde bulunurken seçim yapmaları için daha basit ve daha tutarlı bir yol sunar.",
            "list_section_title": "Grup Başlığı",
            "list_section_subtitle": "Grup Alt Başlığı",
            "list_item_title": "Seçenek Başlığı",
            "list_item_desc": "Seçenek Açıklaması",
            "quick_reply": "Quick Reply",
            "quick_reply_buttons": "Quick Reply Butonları",
            "quick_reply_buttons_desc": "Müşterilerin mesajınıza yanıt vermesini sağlayacak en fazla 3 düğme oluşturun.",
            "quick_reply_button_title": "Quick Reply Buton Adı",
            "template_elementName_desc": "Ad özgün olmali ve yalnızca küçük harfler, sayılar ve alt çizgiler içerebilir",
            "template_vertical_desc": "Bu şablonun hangi kullanım durumuna hizmet ettiğini 2-3 kelimeyle tanımlayın. Örneğin; Hesap güncellemesi, OTP vb.",
            "send_template_message": "Şablon Gönder",
            "select_approved_template": "Lütfen onaylanmış şablonlar arasından seçim yapın",
            "import_success": "Kategoriler Başarıyla İçe Aktarıldı",
            "import_error": "Kategorileri İçe Aktarırken Sorun Oluştu",
            "removed_template_messages": "Silinmiş Şablon Mesajları"
        },
        "user": {
            "password": "Şifre",
            "password_confirmation": "Şifre Onayı",
            "password_change": "Şifre Değiştir",
            "login": "Giriş",
            "logout": "Çıkış",
            "login_at": "Son giriş",
            "logout_confirm": "Sistemden çıkış yapmayı onaylıyor musunuz?",
            "preffered_message_languages": "Tercih Edilen Mesaj Dil(ler)i",
            "ns": "Ad Soyad"
        },
        "pixel": {
            "tools": "Araçlar",
            "properties": "Özellikler",
            "height": "Yükseklik",
            "width": "Genişlik",
            "dimension": "Boyut",
            "color": "Renk",
            "text": "Metin",
            "itext": "IMetin",
            "textbox": "MetinKutusu",
            "rectangle": "Dikdörtgen",
            "triangle": "Üçgen",
            "line": "Hat",
            "polygon": "Çokgen",
            "draw": "Çizim",
            "mode": "Mod",
            "line_width": "Hat uzunluğu",
            "line_color": "Hat rengi",
            "line_shadow": "Hat Gölgesi",
            "pencil": "Kalem",
            "circle": "Çember",
            "spray": "Spray",
            "pattern": "Desen",
            "hline": "Yatay çizgi",
            "vline": "Dikey çizgi",
            "square": "Kare",
            "diamond": "Elmas",
            "texture": "Doku",
            "font": "Yazı tipi",
            "font_size": "Yazı boyutu",
            "text_line_height": "Satır yüksekliği",
            "text_char_spacing": "Karakter aralığı",
            "text_align": "Metin hizalama",
            "text_left": "Sol",
            "text_center": "Merkez",
            "text_right": "Sağ",
            "text_justify": "Yasla",
            "text_justify_left": "Sola-yasla",
            "text_justify_center": "Merkese-yasla",
            "text_justify_right": "Sağa-yasla",
            "text_background_color": "Metin arkaplan rengi",
            "align": "Hizala",
            "pixel": "Pixel",
            "style": "Stil",
            "shapes": "Şekiller",
            "images": "Görseller",
            "filters": "Filtreler",
            "layers": "Katmanlar",
            "empty_filters": "Filtreleri uygulamadan önce bir nesne seçmeniz gerekir.",
            "empty_layers": "Henüz katman yok.",
            "empty_move": "Taşınamaz; hedef seçilmedi",
            "empty_align": "Hizalanamaz; hedef seçilmedi",
            "empty_manage": "Yönetilemez; hedef seçilmedi",
            "sample_text": "Örnek Metin Buraya Gelecek",
            "filter_blur": "Bulanıklaştır",
            "filter_blur_enable": "Yapılandırmayı uygulamadan önce bulanıklaştırmayı etkinleştirin.",
            "filter_grayscale": "Gri Tonlamalı",
            "filter_pixelate": "Pikselleştir",
            "filter_pixelate_pixel": "Piksel",
            "filter_pixelate_enable": "Yapılandırmayı uygulamadan önce pikselleştirmeyi etkinleştirin.",
            "opacity": "Opaklık",
            "shadow": "Gölge",
            "shadow_enable": "Yapılandırmayı uygulamadan önce gölgeyi etkinleştirin.",
            "offset_x": "Ofset X",
            "offset_y": "Ofset Y",
            "stroke": "Kontur",
            "stroke_enable": "Konfigürasyonu uygulamadan önce konturu etkinleştirin.",
            "preview": "Önizleme",
            "social": "Sosyal",
            "feelings": "Duygular",
            "alert": "Pixel uygulamasını en verimli şekilde kullanmak için tablet ve daha büyük ekranları kullanmanızı öneriyoruz"
        },
        "confirmation": {
            "title": "Onaylama",
            "request": "Onay Talebi",
            "broadcast_notify": "{user}, #{id} nolu onay talebiniz {reviewed_by} tarafından incelendi ve {reviewed_at} tarihinde {status}.",
            "database_notify": "#{id} nolu onay talebiniz {reviewed_by} tarafından incelendi ve {reviewed_at} tarihinde {status}.",
            "email_notify_1": "#{id} nolu onay talebiniz **{status}**",
            "email_notify_2": "Onay talebi, **{reviewed_by}** tarafından **{reviewed_at}** tarihinde incelenmiştir.",
            "broadcast_request": "{requested_by} tarafından {created_at} tarihinde #{id} nolu onay talebi oluşturulmuştur.",
            "database_request": "{requested_by} tarafından {created_at} tarihinde #{id} nolu onay talebi oluşturulmuştur.",
            "email_request_1": "{requested_by} tarafından incelemeniz için {created_at} tarihinde #{id} nolu onay talebi oluşturulmuştur.",
            "confirmed": "Onaylandı",
            "declined": "Reddedildi",
            "declined_and_returned": "Geri Gönderildi",
            "unknown": "Bilinmiyor",
            "require": "Her gönderiyi onayla",
            "related_account": "İlgili Hesap",
            "show_full_conversation": "Tüm Görüşmeyi Göster",
            "customer_profile_source": "Müşteri Profili",
            "requested_by": "Talep Eden",
            "review_by": "İnceleyen",
            "reviewed": "İncelendi",
            "created": "Oluşturuldu",
            "accepted": "Onaylandı",
            "rejected": "Reddedildi",
            "waiting": "Bekliyor",
            "awaiting": "Beklemede",
            "awaiting_confirmation": "Onay Bekliyor",
            "confirmation_process": "İki Aşamalı Onay",
            "confirmation_process_desc": "Bu seçenek aktifse, mesajlar Admin onayı olmadan gönderilemez",
            "restricted_word_mail_subject": "Restricted Word Detected !! (Confirmation Required)",
            "show_confirmation": "Show confirmation",
            "restricted_word_notify_mail_subject": "Restricted Word Confirmation {status}",
            "restricted_word_title": "Kısıtlanmış Kelime Onayı",
            "restricted_word_broadcast_notify": "#{id} nolu onay talebi {reviewed_by} tarafından incelendi ve {reviewed_at} tarihinde {status}.",
            "restricted_word_broadcast_request": "{requested_by} tarafından {created_at} tarihinde oluşturulan #{id} için onayınıza ihtiyaç vardır.",
            "restricted_word_database_notify": "#{id} nolu onay talebi {reviewed_by} tarafından incelendi ve {reviewed_at} tarihinde {status}.",
            "restricted_word_database_request": "{requested_by} tarafından {created_at} tarihinde #{id} nolu onay talebi oluşturulmuştur."
        },
        "configuration": {
            "toolbar": "Yapılandırma sayfası: Hesap ekleme işlemleri",
            "player_mode": "Player Mode : ",
            "player_desc": "Player Ayarları",
            "player_mode_desc": "Player, hedeflerinizi belirleyebilirsiniz.",
            "gamification": "Gamification",
            "gamification_desc": "Amaç: Kullanıcıya otomatik aktarılan mesajlara cevap vermektir.",
            "inboxzero": "Inbox ZERO",
            "inboxzero_desc": "Amaç: Mesaj kutusunda cevaplanmamış hiçbir mesaj-ticket bırakmamaktır.",
            "gamification_counter": "Gamification Sayacı : ",
            "gamification_counter_desc": "Bir kullanıcıya atanabilecek maksimum aktif ticket sayısıdır.",
            "gamification_last_reply_counter": "Tanıdık Müşteri Sayacı: ",
            "gamification_last_reply_counter_desc": "Müşteri onunla son ilgilenen Player'a yönlendirilir. Bu rakam yönlendirilebilecek maksimum tanıdık müşteri sayısını belirtir.",
            "warnings": "Uyarılar",
            "warnings_desc": "Popüler müşteri ve doğal olmayan mesaj yoğunluğu uyarıları",
            "message_frequency": "Mesaj Sıklığı",
            "message_frequency_desc": "Bir dakika içindeki anlık mesaj sıklığı",
            "popular_account": "Populer Müşteri",
            "popular_account_desc": "Popüler hesap olmak için gerekli minimum takipçi sayısı",
            "crm_integration": "CRM",
            "crm_configurations": "CRM Ayarları",
            "crm_configurations_desc": "CRM entegrasyon bilgileri",
            "crm_company_name": "Şirket İsmi",
            "crm_company_desc": "Entegrasyon oluşturulan CRM şirketi",
            "crm_company_name_length": "CRM şirketinin adı 5-30 karakter olmalıdır.",
            "crm_company_id": "Company ID",
            "crm_company_id_desc": "Company ID will be provided by your CRM Company, You can get that from them.",
            "crm_company_id_length": "CRM Company ID must be at least 1 characters long.",
            "crm_username": "CRM - Kullanıcı Adı",
            "crm_username_desc": "Entegrasyon için kullanılacak CRM kullanıcı adı",
            "crm_username_length": "Kullanıcı Adı 5-30 karakter olmalıdır.",
            "crm_pass": "CRM - Şifre",
            "crm_pass_desc": "Entegrasyon için kullanılacak CRM şifresi",
            "crm_pass_length": "Şifre 5-18 karakter olmalıdır.",
            "crm_api_url": "URL",
            "crm_api_url_desc": "CRM entegrasyonu için gerekli URL",
            "crm_int_mode": "Entegrasyon Modu",
            "crm_int_mode_desc": "CRM Entegrasyonu - Aktif/Pasif",
            "crm_error_title": "Ticket(CRM) oluşturulurken bir şeyler ters gitti. Sorunu çözmek için destek ekibiyle iletişime geçiniz.",
            "true": "Aktif",
            "false": "Pasif",
            "translation_api_key": "Google Cloud Translation API Anahtarı",
            "translation_status": "Servis Durumu",
            "translation_configuration": "Dil Algılama Servisi Yapılandırması",
            "translation_desc": "Gelen Mesajlar İçin Dil Algılama Servis Ayarları",
            "notification_status": "Bildirim Durumu",
            "notification_configuration": "Bildirim Yapılandırması",
            "notification_desc": "Gerçek Zamanlı Bildirim Ayarları",
            "whatsapp_configuration": "Whatsapp Yapılandırması",
            "whatsapp_desc": "Whatsapp Bildirim Ayarları",
            "email_configuration": "Email Configuration",
            "email_configuration_smtp": "Email Configuration(SMTP)",
            "email_host": "Email host",
            "email_username": "Email Username",
            "email_password": "Email password",
            "email_port": "Email port",
            "send_for": "Send for",
            "time_range": "Time range",
            "message_type": "Message type",
            "select_integrations": "Select integrations",
            "select_accounts": "Select accounts",
            "ticket_notify": "Bilet Hatırlatma Yapılandırması",
            "ticket_notify_desc": "Seçili bilet durumları için hatırlatma ayarları",
            "ticket_notify_status": "Hatırlatma Durumu",
            "ticket_notify_cycle": "Hatırlatma Zamanları",
            "ticket_notify_cycle_day": "Gün(ler) sonra",
            "ticket_notify_cycle_hour": "Saatinde",
            "report_notify": "Rapor Otomasyon Yapılandırması",
            "report_notify_desc": "Rapor Otomasyonu Ayarları"
        },
        "customer": {
            "title": "Müşteri",
            "identification": "Kimlik No",
            "identification_desc": "Pasaport Numarası",
            "id": "No",
            "id_desc": "Müşteri No",
            "username": "Kullanıcı Adı",
            "username_desc": "Müşteri Kullanıcı Adı",
            "name": "Ad",
            "name_desc": "Müşeri Adı",
            "surname": "Soyad",
            "customer_surname": "Müşteri Soyadı",
            "phone": "Telefon",
            "phones": "Telefon Numaraları",
            "phone_desc": "Müşteri Telefon Numarası",
            "email": "E-mail",
            "emails": "E-mailler",
            "email_desc": "Müşteri e-mail",
            "gender": "Cinsiyet",
            "genders": "Cinsiyetler",
            "gender_desc": "Cinsiyet",
            "gender_man": "Erkek",
            "gender_woman": "Kadın",
            "gender_unknown": "Bilinmiyor",
            "gender_search": "Cinsiyete göre ara",
            "gender_select": "Cinsiyet Seç",
            "type": "Tip",
            "history": "Geçmiş",
            "no_history": "Değişiklik mevcut değil",
            "type_desc": "Müşteri tipi",
            "type_select": "Tip",
            "about": "Hakkında",
            "statuses": "Durum",
            "verified": "Onaylı",
            "protected": "Gizli",
            "following": "Takip",
            "followers": "Takipçi",
            "url": "Link",
            "urls": "Linkler",
            "block": "Müşteri Engelleme",
            "block_twitter": "Engel (Twitter)",
            "block_confirm": "Bu kullanıcıyı sosyal medyadan **\"ENGELLEMEK\"** istiyor musunuz?",
            "block_success": "Müşteri başarıyla bloklandı.",
            "block_verify": "Evet, blokla.",
            "moved_to_trash": "Müşteri çöpe gönderildi",
            "trash_restored": "Müşteri çöpten çıkartıldı",
            "trash_created_by": "Çöpe gönderen:",
            "trash_restored_by": "Geri gönderen",
            "location": "Lokasyon",
            "customer_location": "Müşteri lokasyonu",
            "no_location": "Lokaksyon bulunamadı",
            "country": "Ülke",
            "country_code": "Ülke Kodu",
            "postal_code": "Posta Kodu",
            "state_code": "Bölge",
            "county": "İlçe",
            "district": "Mahalle",
            "address": "Adres",
            "addresses": "Adresler",
            "city": "Şehir",
            "street": "Sokak",
            "work": "İş",
            "company": "Şirket",
            "department": "Departman",
            "unread_count": "Okunmamış Mesaj(lar)",
            "active_tickets": "Aktif Ticket(lar)",
            "assigned_by": "Üstlendi",
            "unassigned": "Bıraktı",
            "is_replied_by": "{user_name} Tarafından Cevaplanıyor",
            "customer_type": "Müşteri Tipi",
            "customer_gender": "Müşteri Cinsiyeti",
            "whatsapp_session_alert": "Son müşteri mesajının üzerinden 24 saatten fazla zaman geçti. WhatsApp kurallarına göre, son müşteri mesajından itibaren 24 saatlik oturum penceresinin sona ermesinden sonra işletme, onaylanmış mesaj şablonlarını kullanmadan son kullanıcıya yanıt veremez."
        },
        "simple": {
            "email": "E-Mail Adresi",
            "email_not_verified": "Doğrulama başarısız! Lütfen e-posta yapılandırmanızı kontrol edın.",
            "email_verified": "E-Mail Adresi/Şifresi Doğrulandı",
            "send_test_email": "Test E-Mail",
            "password": "Şifre",
            "password_confirmation": "Şifreyi Onayla",
            "name": "Ad",
            "surname": "Soyad",
            "phone": "Telefon",
            "phone_number": "Telefon Numarası",
            "role": "Rol",
            "display_name": "Ekran Adı",
            "type_to_search": "Aramak için yaz",
            "clear": "Temizle",
            "username": "Kullanıcı Adı",
            "accounts": "Hesaplar",
            "select_account": "Hesap Seçin",
            "account": "Hesap",
            "service_provider": "API Servis Sağlayıcısı",
            "users": "Kullanıcılar",
            "user": "Kullanıcı",
            "team": "Takım",
            "super": "Süper Admin",
            "save": "Kaydet",
            "ok": "Tamam",
            "history": "Geçmiş",
            "post": "İleti",
            "add": "Ekle",
            "edit": "Düzenle",
            "file": "File",
            "view": "Göster",
            "cancel": "İptal",
            "confirm": "Onayla",
            "select": "Seç",
            "remove": "Kaldır",
            "update": "Güncelle",
            "updated": "Güncellendi",
            "created": "Oluştur",
            "created_at": "Oluşturulma Zamanı",
            "status": "Durum",
            "running": "Çalışıyor",
            "failing": "Kapalı",
            "send_failed": "Send Failed!",
            "actions": "Aksiyonlar",
            "integration": "Entegrasyonlar",
            "integration_info": "Uygulama yetkisi almak için harici bir siteye yönlendirileceksiniz!",
            "success": "Başarılı",
            "success_info": "İşleminiz başarıyla tamamlandı!",
            "title": "Konu",
            "desc": "Açıklama",
            "content": "İçerik",
            "login_with_fb": "Facebook ile giriş",
            "type": "Tür",
            "color": "Renk",
            "important": "Önemli",
            "vip": "VIP",
            "example": "Örnek",
            "yes": "Evet",
            "no": "Hayır",
            "records_not_found": "Kayıtlar bulunamadı",
            "activate": "Etkinleştir",
            "date": "Tarih",
            "disable": "Etkisiz",
            "delete": "Sil",
            "delete_confirm": "Yapılan silme işlemini geri alamazsınız!",
            "delete_button": "Evet, sil!",
            "deleted": "Silindi",
            "deleted_info": "Silinen mesaj",
            "deleted_confirmed": "Kayıt başarıyla silindi.",
            "parent": "Kaynak",
            "error": "Hata",
            "error_info": "Bir şeyler yanlış gitti! Lütfen logları kontrol edin!",
            "connect": "Bağla",
            "failed": "Bu kimlik bilgileri kayıtlarımızla eşleşmiyor.",
            "throttle": "Çok fazla giriş denemesi yapıldı. Lütfen  {seconds} saniye sonra tekrar deneyin.",
            "close": "Tamam, Kapat",
            "awaiting": "Bekliyor",
            "unresolved": "Çözülmemiş",
            "solved": "Çözülmüş",
            "track": "Key",
            "track_info": "Hashtag'ler \"#\" ile başlar, bahsetmeler \"@\" ile başlar",
            "permission": "Yetki",
            "message_delete": "Mesajı sil",
            "message_deleted": "Mesaj silindi",
            "unknown": "Bilinmiyor",
            "scheduled": "Planlandı",
            "message_delete_confirm": "Bu mesajı *\"SİLMEK\"* istediğinizden emin misiniz?",
            "delete_verify": "Evet, devem et.",
            "parent_post": "Kaynak Gönderi",
            "sent": "Gönderildi",
            "canceled": "İptal Edildi",
            "map": "Harita",
            "maps": "Haritalar",
            "view_profile": "Profil",
            "hide": "Gizle",
            "unhide": "Göster",
            "message_hide": "Mesaj gizlendi",
            "message_unhide": "Mesaj tekrar görünür oldu",
            "hide_verify": "Evet, Gizle",
            "unhide_verify": "Evet, Göster",
            "hidden_info": "Gizli mesaj",
            "message_hide_confirm": "Bu mesajı *\"Gizlemek\"* istediğinize emin misiniz?",
            "message_unhide_confirm": "Bu mesajı *\"Görünür Yapmak\"* istediğinize emin misiniz?",
            "popular_email_notify": "{customer} (Popülar Hesap) - @{account_username} {integration_id} üzerinden mesaj gönderdi.",
            "vip_email_notify": "{customer} (VIP Hesap) - @{account_username} {integration_id} account üzerinden mesaj gönderdi",
            "message_frequency_email_notify": "{account_name} ({account_username}) {integration_id} hesabınız çok sayıda mesaj-yorum aldı!",
            "checked_at": "Seçim Tarihi",
            "message_count": "Mesaj Sayısı",
            "platform": "Platform",
            "email_notification": "Email Bildirimi",
            "whatsapp_notification": "Whatsapp Bildirimi",
            "report_notification": "Rapor Bildirimi",
            "language": "Dil",
            "left_characters": "Kalan",
            "detect": "Çalıştır",
            "crm": "CRM",
            "sent_as": "Sent as",
            "add_message": "Add Message",
            "add_message_by_url": "Add message by URL",
            "add_users": "Kullanıcıları Ekle",
            "delete_users": "Seçilen Kullanıcıları Sil",
            "edit_users": "Kullanıcıları Düzenle",
            "url": "URL",
            "add_url_desc": "Copy tweet, mention or comment URL here.",
            "sender": "Sender",
            "this_message_exist": "This Message exist.",
            "api_token": "API Token",
            "message": "Mesaj",
            "agent": "Agent",
            "crm_show_name": "Show name on CRM messages",
            "activate_pagination": "Activate Pagination",
            "deactivate_pagination": "Deactivate pagination",
            "new_message_email_notify": "New message arrived for {account_name} ({account_username}) {integration_id} account!",
            "new_message_arrived": "New message arrived",
            "crm_error_subject": "CRM Sistem Hatası: Eylem Gerekiyor",
            "received_at": "Received at",
            "from": "From",
            "to": "To",
            "default": "Varsayılan",
            "twitter_connect": "Twitter Bağlantıları",
            "twitter_connect_desc": "Twitter(X)'in sistemindeki değişiklikler nedeniyle, sorunsuz bir deneyim yaşamanız için lütfen aşağıdaki tüm uygulamalara yetki verin.",
            "twitter_connect_auth": "Twitter hesabını yetkilendir",
            "total_tweet_count": "Toplam Tweet Sayısı",
            "edit_whatsapp_profile": "WhatsApp profilini düzenle",
            "recipients": "Alıcılar",
            "delivered": "İletildi",
            "read": "Okundu",
            "create_campaign": "Kampanya Oluştur",
            "edit_campaign": "Kampanyayı Düzenle",
            "delete_campaign": "Kampanyayı Sil",
            "start_campaign": "Kampanyayı Başlat",
            "campaign_details": "Kampanya Detayları",
            "campaign_name": "Kampanya Adı",
            "campaign_id": "Kampanya No",
            "campaign_overview": "Kampanya Özeti",
            "campaign_schedule_desc": "Kampanya şu saatte yayınlanacak şekilde ayarlandı: {schedule_at}",
            "campaign": "Kampanya",
            "campaigns": "Kampanyalar",
            "campaigns_desc": "Buradan Whatsapp kampanyalarını yönetebilir, düzenleyebilir veya kaldırabilirsiniz",
            "whatsapp_campaigns": "WhatsApp Kampanyaları",
            "audience": "Hedef Kitle",
            "fallback_value": "Geri dönüş değeri (Eğer seçilen değer bulunamazsa)",
            "back": "Geri",
            "send": "Gönder",
            "send_now": "Şimdi Gönder",
            "audience_col_desc": "'phone_number' sütunu zorunludur.",
            "audience_phone_desc": "Telefon numarasının öneki ülke kodu olmalı ve '+' simgesi olmamalıdır. Örneğin. 905551234567",
            "audience_auth": "Yüklenen kişilerin bu iletişimi almayı kabul ettiğini onaylıyorum",
            "whatsapp_attachment_duration_error": "{saniye} saniyeden kısa süreli dosyalar eklenemez!",
            "test_email": "This is a test mail",
            "test_email_content": "This is a test mail content",
            "message_sent": "Message sent successfully",
            "host_problem": "Host Problem",
            "username_problem": "Username Problem",
            "password_problem": "Password Problem",
            "other_problem": "Something wrong",
            "open_link": "Open link",
            "show_preferred_lang_messages": "Tercih edilen dillerdeki mesajlarını göster",
            "show_all_lang_messages": "Tüm dillerdeki mesajlarını göster",
            "import": "Yükle",
            "download_simple_file": "Örnek Dosyayı İndir",
            "deleted_at": "Silinme Tarihi"
        },
        "schedule": {
            "sent": "Gönderildi",
            "cancelled": "Reddedildi",
            "awaiting": "Beklemede",
            "date_range": "Zaman aralığı seç",
            "scheduled_at": "Planlandı: ",
            "pending": "Bekliyor",
            "schedule_desc": "Cevap şu saatte yayınlanacak şekilde ayarlandı: {schedule_at}"
        },
        "page": {
            "homepage": "Anasayfa",
            "questions": "Sohbetler",
            "answers": "Cevaplar",
            "transfers": "Transferler",
            "transfers_desc": "Bire bir transferler",
            "confirmations_list": "Onay Talepleri",
            "confirmations_history": "Onay Geçmişi",
            "confirmations": "Onay Talepleri",
            "confirmations_desc": "Onay istekleri ile ilgili işlemler",
            "confirmations_history_desc": "Onay istekleri geçmişi",
            "conf_note": "Onay Notu",
            "content": "İçerik",
            "attachment": "Ek",
            "attachments": "Ekler",
            "inbox": "Inbox",
            "inbox_desc": "Inbox where all messages are coming",
            "archive": "Arşiv",
            "tickets": "Ticketlar",
            "spam": "Spam",
            "trash": "Çöp",
            "tracks": "Keyler",
            "tracks_desc": "Takip edilen ve sohbetlere dahil edilen keyler",
            "tracks_limit": "Key sınırına ulaştınız. Mevcut keyleri silin veya değiştirin.",
            "tracks_remaining": "Kalan Key Sayısı",
            "mutes": "Sessizler",
            "mutes_desc": "Sessize alınan hesaplar ile ilgili işlemler",
            "blocks": "Bloklamalar",
            "blocks_desc": "Bloklanan hesaplar ile ilgili işlemler",
            "management": "Yönetim",
            "configurations": "Yapılandırma",
            "configurations_desc": "Hesap faaliyetleri ile ilgili yapılandırma ayarları",
            "accounts": "Hesaplar",
            "accounts_desc": "Hesap yönetimi ile ilgili işlemler",
            "announcements": "Duyurular",
            "announcements_desc": "Duyurular ile ilgili işlemler",
            "categories": "Kategoriler",
            "categories_desc": "Kategori yönetimi ile ilgili işlemler",
            "categories_select_desc": "Kategori seçin (varsa)",
            "categorytypes": "Kategori Tipleri",
            "categorytypes_desc": "Kategori tiplerinin yönetimi ile ilgili işlemler",
            "customertypes": "Müşteri Tipleri",
            "customertypes_desc": "Müşteri tiplerinin yönetimi ile ilgili işlemler",
            "customer_genders": "Müşteri Cinsiyetleri",
            "customer_genders_desc": "Müşteri cinsiyetleri yönetimi ile ilgili işlemler",
            "gallery": "Galeri",
            "gallery_desc": "Yüklenen resimler",
            "integrations": "Entegrasyonlar",
            "integrations_desc": "Entegrasyon yönetimi ile ilgili işlemler",
            "tags": "Etiketler",
            "tags_desc": "Etiket yönetimi ile ilgili işlemler",
            "macros": "Şablonlar",
            "macros_desc": "Şablon yönetimi ile ilgili işlemler",
            "macros_categories": "Şablon Kategorileri",
            "macros_categories_desc": "Şablon Kategori yönetimi ile ilgili işlemler",
            "users": "Kullanıcılar",
            "users_desc": "Kullanıcı yönetimi ile ilgili işlemler",
            "roles": "Roller",
            "roles_desc": "Kullanıcı rolleri ile ilgili işlemler",
            "teams": "Takımlar",
            "teams_desc": "Takım yönetimi ile ilgili işlemler",
            "notifications": "Bildirimler",
            "notifications_desc": "Burada bildirimlerinizi kontrol edebilirsiniz",
            "comments": "yorumlar",
            "new": "Yeni",
            "player": "Player",
            "crm_configurations": "CRM Ayarları",
            "search": "Arama",
            "settings": "Ayarlar",
            "search_questions_desc": "Gelen mesajlarda arama",
            "search_answer_desc": "Gönderilen cevaplarda arama",
            "search_tickets_desc": "Ticket özelinde arama",
            "search_customers_desc": "Kullanıcı özelinde arama",
            "upload_file": "Dosya yükleme...",
            "upload_file_check": "Dosya yükleme( Max {size_limit} )",
            "variables_edit": "Değişken Düzenleme",
            "close_tickets": "Kayıt Kapatma",
            "close_tickets_desc": "Bir paylaşıma gönderilen yorumları(kayıt numaralarını) toplu olarak \"Kapalı\" duruma getirebilirsiniz."
        },
        "validation": {
            "accepted": "{attribute} - Kabul edilmelidir.",
            "active_url": "{attribute} - Geçerli bir URL değil.",
            "after": "The {attribute}, {date} tarihinden sonraki bir tarih olmalıdır.",
            "after_or_equal": "{attribute}, {date} tarihinden sonra veya ona eşit olmalıdır.",
            "alpha": "{attribute}, yalnızca harf içerebilir.",
            "alpha_dash": "{attribute}, yalnızca harf, rakam ve kısa çizgi içerebilir.",
            "alpha_num": "{attribute}, yalnızca harf ve rakam içerebilir.",
            "array": "{attribute} bir dizi olmalıdır.",
            "before": "{attribute}, {date} tarihinden önceki bir tarih olmalıdır.",
            "before_or_equal": "{attribute}, {date} tarihinden önceki veya ona eşit bir tarih olmalıdır.",
            "between": {
                "numeric": "{attribute}, {min} ile {max} arasında olmalıdır.",
                "file": "{attribute}, {min} ile {max} kilobayt arasında olmalıdır.",
                "string": "{attribute}, {min} ile {max} karakter arasında olmalıdır.",
                "array": "{attribute}, {min} ile {max} arasında öğe içermelidir."
            },
            "boolean": "{attribute}, alanı 'true' veya 'false' olmalıdır.",
            "confirmed": "{attribute} onayı eşleşmiyor.",
            "date": "{attribute}, geçerli bir tarih değil.",
            "date_format": "{attribute}, {format} biçimiyle eşleşmiyor.",
            "different": "{attribute} ve {other} farklı olmalıdır.",
            "digits": "{attribute}, {digits} rakam olmalıdır.",
            "digits_between": "{attribute}, {min} ile {max} basamak arasında olmalıdır.",
            "dimensions": "{attribute}, geçersiz resim boyutlarına sahip.",
            "distinct": "{attribute}, alanında yinelenen bir değer var.",
            "email": "{attribute}, geçerli bir e-posta adresi olmalıdır.",
            "exists": "Seçili {attribute} geçersiz.",
            "file": "{attribute} bir dosya olmalıdır.",
            "filled": "{attribute}, alanı bir değere sahip olmalıdır.",
            "image": "{attribute}, bir resim olmalıdır.",
            "in": "Seçilen {attribute} geçersiz.",
            "in_array": "{attribute} alanı {other} içinde mevcut değil.",
            "integer": "{attribute} bir tam sayı olmalıdır.",
            "ip": "{attribute}, geçerli bir IP adresi olmalıdır.",
            "ipv4": "{attribute}, geçerli bir IPv4 adresi olmalıdır.",
            "ipv6": "{attribute}, geçerli bir IPv6 adresi olmalıdır.",
            "json": "{attribute}, geçerli bir JSON dizesi olmalıdır.",
            "max": {
                "numeric": "{attribute}, {max} değerinden büyük olamaz.",
                "file": "{attribute}, {max} kilobayttan büyük olamaz.",
                "string": "{attribute}, {max} karakterden büyük olamaz.",
                "array": "{attribute}, {max} öğeden fazla olamaz."
            },
            "mimes": "{attribute}, şu türde bir dosya olmalıdır => {values}.",
            "mimetypes": "{attribute}, şu türde bir dosya olmalıdır => {values}.",
            "min": {
                "numeric": "{attribute}, en az {min} olmalıdır.",
                "file": "{attribute}, en az {min} kilobayt olmalıdır.",
                "string": "{attribute}, en az {min} karakter olmalıdır.",
                "array": "{attribute}, en az {min} öğeye sahip olmalıdır."
            },
            "not_in": "Seçilen {attribute} geçersiz.",
            "numeric": "{attribute} bir sayı olmalıdır.",
            "present": "{attribute} alanı mevcut olmalıdır.",
            "regex": "{attribute} biçimi geçersiz.",
            "required": "{attribute} alanı gereklidir.",
            "required_if": "{other}, {value} olduğunda {attribute} alanı gereklidir.",
            "required_unless": "{other}, {values} içinde olmadığı sürece {attribute} alanı gereklidir.",
            "required_with": "{value} mevcut olduğunda {attribute} alanı gereklidir.",
            "required_with_all": "{attribute} alanı, {values} mevcut olduğunda gereklidir.",
            "required_without": "{attribute} alanı, {values} mevcut olmadığında gereklidir.",
            "required_without_all": "{attribute} alanı, {values} hiçbiri mevcut olmadığında gereklidir.",
            "same": "{attribute} ve {other} eşleşmelidir.",
            "size": {
                "numeric": "{attribute}, {size} olmalıdır.",
                "file": "{attribute}, {size} kilobayt olmalıdır.",
                "string": "{attribute}, {size} karakter olmalıdır.",
                "array": "{attribute}, {size} öğe içermelidir."
            },
            "string": "{attribute}, bir dize olmalıdır.",
            "timezone": "{attribute}, geçerli bir bölge olmalıdır.",
            "unique": "{attribute}, zaten alınmış.",
            "uploaded": "{attribute} yüklenemedi.",
            "url": "{attribute} biçimi geçersiz.",
            "custom": {
                "attribute-name": {
                    "rule-name": "özel-mesaj"
                }
            },
            "attributes": []
        },
        "default": {
            "naming": "Varsayılan",
            "hi": "Merhaba",
            "hi_user": "Merhaba {user}",
            "welcome": "Hoşgeldiniz",
            "back": "Tekrar",
            "thanks": "Teşekkürler",
            "user": "Kullanıcı",
            "users": "Kullanıcılar",
            "team": "Takım",
            "teams": "Takımlar",
            "save": "Kaydet",
            "cancel": "İptal",
            "reset": "Sıfırla",
            "reset_desc": "Tüm alanları sıfırla",
            "reset_note": "Notu sıfırla",
            "reset_note_desc": "Not alanını temizle",
            "reply": "Yanıtla",
            "reply_desc": "Bir mesaj yazın",
            "add_note": "Not ekle",
            "close": "Kapat",
            "add": "Ekle",
            "edit": "Düzenle",
            "json": "Add json file",
            "remove": "Kaldır",
            "delete": "Sil",
            "preview": "Önizleme",
            "category": "Kategori",
            "note": "Not",
            "select": "Seç",
            "image": "Görsel",
            "images": "Görseller",
            "upload": "Yükle",
            "status": "Durum",
            "active": "Aktif",
            "inactive": "Pasif",
            "refresh": "Yenile",
            "disable": "Etkisiz",
            "disabled": "Etkisiz",
            "enable": "Etkin",
            "enabled": "Etkin",
            "email": "E-Mail Adresi",
            "password": "Şifre",
            "password_confirmation": "Şifreyi Onayla",
            "name": "Ad",
            "surname": "Soyad",
            "desc": "Açıklama",
            "actions": "Aksiyonlar",
            "mute": "Sessiz",
            "blacklist": "Karaliste",
            "login_description": "Dijital Dünya Kontrol Altında!",
            "select_instagram_account": "Select instagram account",
            "select_instagram_account_desc": "Please choose which instagram account you want to add in",
            "select_facebook_account": "Select facebook account",
            "select_facebook_account_desc": "Please choose which facebook account you want to add in",
            "select_linkedin_account": "Select LinkedIn account",
            "select_linkedin_account_desc": "Please choose which LinkedIn account you want to add in",
            "reopen": "Reopen",
            "load_more": "Load more",
            "current_usage": "Güncel kullanım durumu",
            "change": "Değiştir",
            "twitter_limit_notify": "Toplam Tweet sayısı {tweet_count}'a ulaştı! Anlık Limit Durumu: {limit}/{tweet_count}",
            "twitter_limit_title": "Twitter Tweet Limiti",
            "twitter_limit_ended": "Tweet limitiniz doldu!Limitinizi artırmak ve tekrar tweet almaya başlamak için destek ekibiyle iletişime geçin.",
            "twitter_limit_near_ending": "Tweet limitiniz neredeyse doldu! Hizmet kesintisini önlemek ve tweet almayı bırakmamak için destek ekibine başvurun ve limitinizi artırın.",
            "twitter_dm_limit": "Günlük Twitter DM limiti:",
            "twitter_dm_limit_desc": "Twitter DM sınırı {reset_time} tarihinde sıfırlanacak.",
            "twitter_dm_limit_alert": "Günlük DM sınırınıza ulaştınız. Kalan DM Limiti: {remaining_limit}. DM {reset_time}'dan sonra gönderilecektir",
            "video": "Video",
            "business_desc": "İş Tanımı",
            "business_details": "İşletme Bilgileri",
            "contact_info": "İletişim Bilgileri",
            "edit_whatsapp_profile": "Whatsapp İşletme Profilini Düzenle",
            "whatsapp_picture_desc": "Whatsapp profil resmi {min} - {min} ve {max} - {max} boyutları arasında olmalıdır. Maksimum dosya boyutu: {file_size} MB",
            "website": "Website",
            "new_message_arrived": "Yeni bir mesajınız var!",
            "new_message_broadcast": "{customer} yeni mesaj gönderdi: {content}",
            "new_popular_message_arrived": "Popüler Müşteriden yeni bir mesajınız var!",
            "new_popular_message_broadcast": "Popüler {customer}'dan yeni mesaj alındı: {content}",
            "new_vip_account_message_arrived": "VIP Hesap Müşterisinden yeni bir mesajınız var!",
            "new_vip_account_message_broadcast": "VIP Hesap {customer}'dan yeni mesaj alındı: {content}",
            "go_content": "İçeriğe Git",
            "show_more": "Daha Fazla Göster",
            "import_category": "Kategori İçe Aktar"
        },
        "permission": {
            "add": "Ekle",
            "edit": "Düzenle",
            "view": "Görme",
            "delete": "Sil",
            "denied": "Bunun için izniniz yok.",
            "read-transfer": "\"Transferi-okuma\" izniniz yok.",
            "update-transfer": "\"Transferi-güncelleme\" izniniz yok.",
            "read_users": "\"Kullanıcı-okuma\" izniniz yok.",
            "update_users": "\"Kullanıcı-güncelleme\" izniniz yok.",
            "delete_users": "\"Kullanıcı-silme\" izniniz yok.",
            "read_teams": "\"Takım-okuma\" izniniz yok.",
            "update_teams": "\"Takım-güncelleme\" izniniz yok.",
            "delete_teams": "\"takım-silme\" izniniz yok.",
            "create_teamuser": "\"Takım Üyesi-oluşturma\" izniniz yok.",
            "update_teamuser": "\"Takım Üyesi-Güncelleme\" izniniz yok.",
            "create_roles": "\"Rol-oluşurma\" izniniz yok.",
            "read_roles": "\"Rol-okuma\" izniniz yok.",
            "update_roles": "\"rol-güncelleme\" izniniz yok.",
            "delete_roles": "\"rol-silme\" izniniz yok."
        },
        "player": {
            "player": "PLAYER(All In One)",
            "play_mode": "Play Mod",
            "play": "Play",
            "pause": "Pause"
        }
    }
}
